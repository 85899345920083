import { Vue, Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { DE, EN } from '@/plugins/i18n'
import { GET_LOCALE } from '@/store/auth/getter-types'

const auth = namespace('auth')

@Component
export default class TranslationMixin extends Vue {
  @auth.Getter(GET_LOCALE) _translationMixinLocale!: string

  translated (translations: any) {
    const selectedTranslation = this.filterTranslation(translations, this._translationMixinLocale)

    if (selectedTranslation.length !== 1) {
      return false
    }

    return !!this.isValidTranslation(selectedTranslation)
  }

  retrieveTranslation (translations: any) {
    return this.extractTranslation(translations, this._translationMixinLocale)
  }

  filterTranslation (translations: Array<any>, locale: string) {
    return translations.filter(
      translation => {
        return translation.locale && translation.locale === locale
      }
    )
  }

  isValidTranslation (translation: any) {
    return translation.length > 0 && translation[0].title && translation[0].title.length > 0
  }

  extractTranslation (translations: any, locale: string) {
    if (translations.length === 1) {
      return translations[0]
    }

    const selectedTranslation = this.filterTranslation(translations, locale)
    if (this.isValidTranslation(selectedTranslation)) {
      return selectedTranslation[0]
    }

    const fallbackLocale = locale === DE ? EN : DE
    const fallbackTranslation = this.filterTranslation(translations, fallbackLocale)

    return fallbackTranslation.length > 0 ? fallbackTranslation[0] : translations[0]
  }
}
