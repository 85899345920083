<template>
  <microtrend-switch
    :content="content"
    :is-new="isHot || isNew"
    :remove="remove"
    @click="onClick"
  >
    <template v-slot:action>
      <slot name="action"/>
    </template>
    <template v-slot:title-action>
      <slot name="title-action"/>
    </template>
  </microtrend-switch>
</template>

<script>
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { DateMixin } from '@/mixins'

import { GET_LAST_LOGIN } from '@/store/auth/getter-types'
import { MICROTRENDS } from '@/model/types'
import { SET_MICROTREND } from '@/store/gui/mutation-types'

import MicrotrendSwitch from '@/components/microtrend/card/microtrend-switch'

const auth = namespace('auth')
const gui = namespace('gui')

@Component({
  components: {
    MicrotrendSwitch
  }
})
export default class MicrotrendDefaultHandler extends Mixins(DateMixin) {
  @Prop({ type: Object, validator: obj => MICROTRENDS.includes(obj.__typename) }) content
  @Prop({ type: Boolean, default: () => false }) isNew
  @Prop({ type: Boolean, default: () => false }) remove

  @auth.Getter(GET_LAST_LOGIN) lastLogin
  @gui.Mutation(SET_MICROTREND) setMicrotrendForOverlay

  get isHot () {
    return this.content && this.lastLogin && this.content.identifiedAt
      ? (this.unix(this.lastLogin) < this.unix(this.content.identifiedAt))
      : null
  }

  onClick () {
    this.$trackPageView('/microtrend/' + this.content.id, 'TRENDMANAGER | TRENDONE', window.location.href)
    this.setMicrotrendForOverlay(this.content.id)
  }
}
</script>
