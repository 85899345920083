import { SavedUseCasesTextContent } from '@/ui/microtrend/use-cases/saved-use-cases/saved-use-cases.types'
import { UseCaseFormTextContent } from '@/ui/microtrend/use-cases/use-case-form/use-case-form.types'
import { AiGeneratedUseCaseTextContent } from '@/ui/microtrend/use-cases/ai-generated-use-case/ai-generated-use-case.types'

export interface UseCase {
  id: string
  title: string
  text: string
  createdOn: string
  author: string
  deletable: boolean
  editable: boolean
}

export interface UseCaseContent {
  title: string
  text: string
}

export interface TabTitles {
  create: string
  edit: string
  savedUseCases: string
  ai: string
  aiTooltip: string
}

export interface UseCasesTextContent {
  tabTitles: TabTitles
  aiTranslations: AiGeneratedUseCaseTextContent
  savedUseCasesTextContent: SavedUseCasesTextContent
  formTextContent: UseCaseFormTextContent
}

export function emptyUseCaseContent (): UseCaseContent {
  return { title: '', text: '' }
}
