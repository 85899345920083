
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CriteriaSet, Locale, useLoadCriteriaSetsQuery } from '@/graphql/app/types'

@Component<CriteriaSetLoader>({
  apollo: {
    criteriaSets: useLoadCriteriaSetsQuery<CriteriaSetLoader>({
      variables () {
        return {
          locale: this.locale
        }
      }
    })
  }
})
export default class CriteriaSetLoader extends Vue {
  @Prop({ required: true }) locale!: Locale
  criteriaSets: Array<CriteriaSet> = []

  get loading (): boolean {
    return this.$apollo.queries.criteriaSets.loading
  }
}
