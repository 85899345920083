import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'

import Vue from 'vue'

// @ts-ignore
import vuetify from '@/plugins/vuetify'
// @ts-ignore
import i18n, { loadLanguageAsync } from '@/plugins/i18n'
// @ts-ignore
import { router } from '@/plugins/router'
// @ts-ignore
import { apolloProvider } from '@/plugins/apollo'

import '@/plugins/sentry'
import '@/plugins/casl'
import '@/plugins/clipboard'
// import '@/plugins/editor'
import '@/plugins/masonry'
import '@/plugins/meta'
import '@/plugins/infinitive-scroll'
import '@/plugins/portal'
import '@/plugins/resize'
import '@/plugins/vuelidate'
import '@/plugins/matomo'
import '@/plugins/tracker'
import '@/plugins/video-player'

// @ts-ignore
import store from './store'

import App from '@/App.vue'
import { storageLocale } from '@/util/locale-detection'
import { errorHandler } from '@/error-handler/error-handler'

Vue.config.productionTip = false
Vue.config.errorHandler = errorHandler

const initialLocale = storageLocale()

/* eslint-disable no-new */
loadLanguageAsync(initialLocale).then(() => {
  new Vue({
    // @ts-ignore
    router,
    store,
    vuetify,
    i18n,
    apolloProvider,
    // @ts-ignore
    render: h => h(App)
  }).$mount('#app')
})
