export const AXIS_POTENTIAL = 'potential'
export const AXIS_MATURITY = 'maturity'
export const AXIS_COMPETENCES = 'competences'
export const AXIS_CUSTOM = 'CUSTOM'

export const VALUES = [
  AXIS_POTENTIAL,
  AXIS_COMPETENCES,
  AXIS_MATURITY
]
