
import { Component, Prop, Vue } from 'vue-property-decorator'
import { themeOptions } from '@/plugins/theme-options'

@Component
export default class ToIcon extends Vue {
  @Prop({
    type: Boolean,
    default: false
  }) left

  @Prop({
    type: Boolean,
    default: false
  }) right

  @Prop({
    type: String,
    default: () => null
  }) icon

  @Prop({
    type: String,
    default: () => '16px'
  }) size

  @Prop({
    type: Boolean,
    default: () => false
  }) inline

  @Prop({
    type: String,
    default: 'primary'
  }) color

  get usedIcon () {
    return this.icon !== null
      ? this.icon
      : this.$slots.default && this.$slots.default.length > 0
        ? this.$slots.default[0].text.trim()
        : 'dashboard'
  }

  get classes () {
    return `tx4-svg-icon icon-tx4_${this.usedIcon}${this.left ? ' icon-left' : ''}${this.right ? ' icon-right' : ''}`
  }

  get styles () {
    const color = this.color in themeOptions.themes.light
      ? themeOptions.themes.light[this.color]
      : this.color

    return {
      width: this.size,
      height: this.size,
      stroke: color,
      fill: color,
      color: color
    }
  }
}
