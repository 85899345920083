import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { runtimeConfig, searchAndReplace } from '@/util/runtime-config'
const regexes = runtimeConfig('TEXT_REPLACEMENTS', [])

export const i18nReplacer = (object) => {
  if (regexes.length === 0) {
    return object
  }

  return searchAndReplace(object, regexes)
}

Vue.use(VueI18n)

export const DE = 'de'
export const EN = 'en'
export const DEFAULT_LOCALE = DE
export const FALLBACK_LOCALE = DE
export const ALLOWED_LOCALES = [DE, EN]

const loadedLanguages = []

export const i18n = new VueI18n({
  locale: null,
  fallbackLocale: FALLBACK_LOCALE
})

export function setI18nLanguage (lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang) {
  if (!ALLOWED_LOCALES.includes(lang)) {
    lang = DEFAULT_LOCALE
  }

  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  return import(/* webpackChunkName: "lang-[request]" */ `@/plugins/i18n/${lang}/index.js`).then(
    messages => {
      i18n.setLocaleMessage(lang, i18nReplacer(messages.default))
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    }
  )
}

export default i18n
