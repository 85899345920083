import Vue from 'vue'
import * as Mutation from './mutation-types'

export default {
  [Mutation.HIDE_ALL_DIALOGS]: (state) => {
    Vue.set(state, 'delete', false)
    Vue.set(state, 'leave', false)
    Vue.set(state, 'removeNode', false)
    Vue.set(state, 'edit', false)
    Vue.set(state, 'copy', false)
    Vue.set(state, 'currentWorkspace', null)
  },
  [Mutation.SHOW_DELETE_DIALOG]: (state, payload) => {
    Vue.set(state, 'currentWorkspace', payload)
    Vue.set(state, 'delete', true)
  },
  [Mutation.SHOW_EDIT_DIALOG]: (state, payload) => {
    Vue.set(state, 'currentWorkspace', payload || null)
    Vue.set(state, 'edit', true)
  },
  [Mutation.SHOW_LEAVE_DIALOG]: (state, payload) => {
    Vue.set(state, 'currentWorkspace', payload)
    Vue.set(state, 'leave', true)
  },
  [Mutation.SHOW_COPY_DIALOG]: (state, payload) => {
    Vue.set(state, 'currentWorkspace', payload)
    Vue.set(state, 'copy', true)
  },
  [Mutation.SET_SORT]: (state, payload) => {
    state.sort[payload.type] = {
      field: payload.field,
      direction: payload.direction
    }
  },
  [Mutation.SET_XLSX_EXPORT_URL]: (state, payload) => {
    state.xlsxExportUrl = payload
  }
}
