
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { FeatureCheckMixin } from '@/mixins'

import { SET_MICROTRENDS_TO_PIN, SHOW_ADD_TO_WORKSPACE, SHOW_SNACKBAR } from '@/store/gui/mutation-types'
import { MICROTREND } from '@/model/trends/types'

import LoadingCircle from '@/components/loading-circle/loading-circle.vue'
import ToIcon from '@/components/to-icon/to-icon.vue'
import { PropType } from 'vue'
import { SetMicrotrendsToPin, ShowAddToWorkspace, ShowSnackbar } from '@/store/gui/types'

const gui = namespace('gui')

@Component({
  components: {
    LoadingCircle,
    ToIcon
  }
})
export default class MicrotrendAddButton extends Mixins(FeatureCheckMixin) {
  @Prop({
    type: Array as PropType<Array<string>>,
    required: true
  }) microtrendIds!: Array<string>

  @Prop({
    type: Boolean,
    default: () => false
  }) round!: boolean

  @Prop({
    type: Boolean,
    default: () => false
  }) left!: boolean

  @Prop({
    type: String,
    default: () => null
  }) tooltip!: string | null

  @Prop({
    type: String,
    default: () => 'primary'
  }) color!: string

  @Prop({
    type: Boolean,
    default: () => false
  }) flat!: boolean

  @gui.Mutation(SHOW_ADD_TO_WORKSPACE) storeShowAddToWorkspace!: ShowAddToWorkspace
  @gui.Mutation(SET_MICROTRENDS_TO_PIN) storeSetMicrotrendsToPin!: SetMicrotrendsToPin
  @gui.Mutation(SHOW_SNACKBAR) storeShowSnackbar!: ShowSnackbar

  pending = false
  open = false

  get visible () {
    // @ts-ignore
    return this.$can('create', 'MicrotrendPin') || this.$can('manage', 'CompanyMacrotrend') || this.$can('manage', 'Macrotrend')
  }

  get currentTooltip () {
    if (this.addButtonDisabled) {
      return this.$t('components.microtrend.addButton.tooltip.disabled')
    }

    return this.tooltip === null
      ? this.hasWorkspaceFeature && this.hasPinsFeature
        ? this.$t('components.microtrend.addButton.tooltip.addToWorkspaceOrMacrotrend')
        : this.hasWorkspaceFeature
          ? this.$t('components.microtrend.addButton.tooltip.addToWorkspace')
          : this.hasPinsFeature
            ? this.$t('components.microtrend.addButton.tooltip.addToMacrotrend')
            : this.tooltip
      : this.tooltip
  }

  showAddToWorkspace () {
    this.open = false
    this.storeShowAddToWorkspace(this.microtrendIds.map(id => ({
      id,
      type: MICROTREND
    })))
  }

  showPinToMacrotrend () {
    this.open = false
    this.storeSetMicrotrendsToPin(this.microtrendIds)
  }

  get addButtonDisabled () {
    return this.microtrendIds.length < 1
  }
}
