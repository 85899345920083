
import { Component, Mixins, VModel } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { HAS_TYPE, HasType } from '@/store/flag/getter-types'
import { ACTIVELY_CONSENTED, COOKIE_CONSENT, MATOMO, TRACKING_CONSENT } from '@/model/flags'
import ManageCookies from '@/components/consent-management/cookie-consent/manage-cookies.vue'
import ConsentMixin from '@/mixins/consent'

const flagStore = namespace('flag')

@Component<CookieConsent>({
  components: { ManageCookies }
})
export default class CookieConsent extends Mixins(ConsentMixin) {
  @VModel() open!: boolean

  @flagStore.Getter(HAS_TYPE) hasType!: HasType

  showManageCookies = false

  get show (): boolean {
    return !this.getFlag({
      type: COOKIE_CONSENT,
      name: ACTIVELY_CONSENTED
    }) || this.open
  }

  t (item: string) {
    return this.$t(`components.consentManagement.cookieConsent.${item}`)
  }

  get matomoTracking () {
    return this.getFlag({
      type: TRACKING_CONSENT,
      name: MATOMO
    })
  }

  get matomoSelected (): boolean {
    return this.getFlag({
      type: COOKIE_CONSENT,
      name: MATOMO
    }) || false
  }

  get tooltip (): string {
    const state = this.matomoTracking ? 'active' : 'inactive'

    return this.t(`matomo.${state}.tooltip`) as string
  }

  close (): void {
    this.open = false
    this.setFlag({
      type: COOKIE_CONSENT,
      name: ACTIVELY_CONSENTED,
      value: true
    })
  }

  acceptAll () {
    this.saveMatomo(true)
  }

  rejectAll () {
    this.saveMatomo(false)
  }

  saveMatomo (payload: boolean) {
    this.setFlag({
      type: COOKIE_CONSENT,
      name: MATOMO,
      value: payload
    })

    this.setConsents()

    this.close()
  }

  mounted (): void {
    this.setConsents()
  }
}
