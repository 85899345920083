import { render, staticRenderFns } from "./saved-use-cases.vue?vue&type=template&id=632e1bf4&"
import script from "./saved-use-cases.vue?vue&type=script&setup=true&lang=ts&"
export * from "./saved-use-cases.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports