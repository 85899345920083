
import { Component, Vue } from 'vue-property-decorator'
import {
  copyTrendradarDraftMutation,
  CopyTrendradarDraftMutationVariables,
  createPanelMutation,
  createTrendRadarDraftMutation,
  CreateTrendRadarInput,
  deleteOverrideMutation,
  deleteTrendRadarDraftMutation,
  DeleteTrendRadarDraftMutationVariables,
  overrideEvaluationMutation,
  publishTrendRadarDraftMutation,
  republishTrendradarMutation,
  setPercptionMutation,
  setSharingOptionsMutation,
  setTrendRadarDraftEvaluationEnabledMutation,
  unpublishTrendRadarDraftMutation,
  updateTrendRadarDraftMutation
} from '@/graphql/app/types'
import {
  CreatePanelPayload,
  DeleteOverridePayload,
  OverrideEvaluationPayload,
  PublishTrendradarDraftPayload,
  SetEvaluationEnabledPayload,
  SetPerceptionPayload,
  SetTrendradarDraftSharingOptionsPayload,
  UnpublishTrendradarPayload,
  UpdateTrendradarDraftPayload
} from '@/components/trendradar/dashboard/types'
import { SHOW_SNACKBAR } from '@/store/gui/mutation-types'
import { ShowSnackbar } from '@/store/gui/types'
import { namespace } from 'vuex-class'
import { SharingOption } from '@/components/trendradar/dashboard/sharing-options/types'

const gui = namespace('gui')

@Component
export default class TrendradarDraftHandler extends Vue {
  @gui.Mutation(SHOW_SNACKBAR) showSuccess!: ShowSnackbar

  pending: boolean = false
  sharingOptionsPending: Record<SharingOption, boolean> = {
    embedding: false,
    publicView: false
  }

  showError (message: string): void {
    this.showSuccess({
      messages: message,
      mode: 'error'
    })
  }

  async deleteTrendradarDraft (payload: DeleteTrendRadarDraftMutationVariables): Promise<void> {
    try {
      this.pending = true
      await deleteTrendRadarDraftMutation(this, { variables: payload })
      this.showSuccess(this.t('delete.success'))
      this.$emit('delete:success')
    } catch (error) {
      console.log('%cError on deleteTrendRadarDraft´: ', 'color:red', error) // eslint-disable-line no-console
      this.showError(this.t('delete.error'))
    } finally {
      this.pending = false
    }
  }

  async copyTrendradarDraft (payload: CopyTrendradarDraftMutationVariables): Promise<void> {
    try {
      this.pending = true
      await copyTrendradarDraftMutation(this, { variables: payload })
      this.showSuccess(this.t('copy.success'))
      this.$emit('copy:success')
    } catch (error) {
      console.log('%cError on copyTrendRadarDraft´: ', 'color:red', error) // eslint-disable-line no-console
      this.showError(this.t('copy.error'))
    } finally {
      this.pending = false
    }
  }

  async createTrendradarDraft (payload: CreateTrendRadarInput): Promise<void> {
    try {
      this.pending = true
      const response = await createTrendRadarDraftMutation(
        this,
        {
          variables: {
            input: payload
          }
        }
      )
      const id = response.data?.createTrendRadar?.id || null
      if (id) {
        this.showSuccess(this.t('create.success'))
        this.$emit('create:success', id)
      }
    } catch (error) {
      console.log('%cError on createTrendRadarDraft´: ', 'color:red', error) // eslint-disable-line no-console
      this.showError(this.t('create.error'))
    } finally {
      this.pending = false
    }
  }

  async updateTrendradarDraft (payload: UpdateTrendradarDraftPayload): Promise<void> {
    try {
      this.pending = true
      await updateTrendRadarDraftMutation(
        this,
        {
          variables: {
            locale: payload.locale,
            id: payload.id,
            input: payload.input
          }
        }
      )
      this.showSuccess(this.t('update.success'))
    } catch (error) {
      console.log('%cError on updateTrendRadarDraft´: ', 'color:red', error) // eslint-disable-line no-console
      this.showError(this.t('update.error'))
    } finally {
      this.pending = false
    }
  }

  async publishTrendradarDraft (payload: PublishTrendradarDraftPayload) {
    try {
      this.pending = true
      await publishTrendRadarDraftMutation(
        this,
        {
          variables: {
            id: payload.id,
            description: payload.description
          }
        }
      )
      this.$track('Trendradar', 'publish')
      this.showSuccess(this.t('publish.success'))
    } catch (error) {
      console.log('%cError on publishTrendradar´: ', 'color:red', error) // eslint-disable-line no-console
      this.showError(this.t('publish.error'))
    } finally {
      this.pending = false
    }
  }

  async unpublishTrendradarDraft (payload: UnpublishTrendradarPayload): Promise<void> {
    try {
      this.pending = true
      await unpublishTrendRadarDraftMutation(
        this,
        {
          variables: {
            id: payload.id
          }
        }
      )
      this.$track('Trendradar', 'unpublish')
      this.showSuccess(this.t('unpublish.success'))
    } catch (error) {
      console.log('%cError on unpublishTrendRadarDraft´: ', 'color:red', error) // eslint-disable-line no-console
      this.showError(this.t('unpublish.error'))
    } finally {
      this.pending = false
    }
  }

  async setTrendradarDraftSharingOptions (payload: SetTrendradarDraftSharingOptionsPayload): Promise<void> {
    try {
      this.sharingOptionsPending[payload.type] = true
      await setSharingOptionsMutation(
        this,
        {
          variables: {
            id: payload.id,
            input: {
              publicView: payload.publicView,
              embedding: payload.embedding
            }
          }
        }
      )

      this.showSuccess(this.t('setSharingOptions.success'))
    } catch (error) {
      console.log('%cError on setSharingOptions´: ', 'color:red', error) // eslint-disable-line no-console
      this.showError(this.t('setSharingOptions.error'))
    } finally {
      this.sharingOptionsPending[payload.type] = false
    }
  }

  async setEvaluationEnabled (payload: SetEvaluationEnabledPayload): Promise<void> {
    try {
      this.pending = true
      await setTrendRadarDraftEvaluationEnabledMutation(
        this,
        {
          variables: {
            id: payload.id,
            enabled: payload.enabled
          }
        }
      )
      this.showSuccess(this.t(`setEvaluationEnabled.success.${payload.enabled ? 'enabled' : 'disabled'}`))
    } catch (error) {
      console.log('%cError on setEvaluationEnabled´: ', 'color:red', error) // eslint-disable-line no-console
      this.showError(this.t(`setEvaluationEnabled.error.${payload.enabled ? 'enabled' : 'disabled'}`))
    } finally {
      this.pending = false
    }
  }

  async createPanel (payload: CreatePanelPayload): Promise<void> {
    try {
      this.pending = true
      await createPanelMutation(
        this,
        {
          variables: {
            id: payload.id
          }
        }
      )
      this.$track('Panel', 'create')
      this.showSuccess(this.t('createPanel.success'))
    } catch (error) {
      console.log('%cError on createPanel´: ', 'color:red', error) // eslint-disable-line no-console
      this.showError(this.t('createPanel.error'))
    } finally {
      this.pending = false
    }
  }

  async overrideEvaluation (payload: OverrideEvaluationPayload): Promise<void> {
    try {
      this.pending = true
      await overrideEvaluationMutation(
        this,
        {
          variables: {
            id: payload.trendradarId,
            input: payload.input,
            locale: payload.locale
          }
        }
      )
      this.showSuccess(this.t('overrideEvaluation.success'))
    } catch (error) {
      console.log('%cError on overrideEvaluation´: ', 'color:red', error) // eslint-disable-line no-console
      this.showError(this.t('overrideEvaluation.error'))
    } finally {
      this.pending = false
    }
  }

  async setPerception (payload: SetPerceptionPayload): Promise<void> {
    try {
      this.pending = true
      await setPercptionMutation(
        this,
        {
          variables: {
            id: payload.trendradarId,
            input: payload.input,
            locale: payload.locale
          }
        }
      )
      this.showSuccess(this.t('setPerception.success'))
    } catch (error) {
      console.log('%cError on setPerception´: ', 'color:red', error) // eslint-disable-line no-console
      this.showError(this.t('setPerception.error'))
    } finally {
      this.pending = false
    }
  }

  async republishTrendradar (trendradarId: string): Promise<void> {
    try {
      this.pending = true
      console.log(trendradarId)
      await republishTrendradarMutation(
        this,
        {
          variables: {
            id: trendradarId
          }
        }
      )
      this.showSuccess(this.t('republish.success'))
    } catch (error) {
      console.log('%cError on republish´: ', 'color:red', error) // eslint-disable-line no-console
      this.showError(this.t('republish.error'))
    } finally {
      this.pending = false
    }
  }

  t (token: string): string {
    const prefix = 'components.trendradar.handler.draft'
    return this.$t(`${prefix}.${token}`).toString()
  }

  async deleteOverride (payload: DeleteOverridePayload): Promise<void> {
    try {
      this.pending = true
      await deleteOverrideMutation(
        this,
        {
          variables: {
            id: payload.trendradarId,
            macrotrendId: payload.macrotrendId,
            locale: payload.locale
          }
        }
      )
      this.showSuccess(this.t('deleteOverride.success'))
    } catch (error) {
      console.log('%cError on delete override´: ', 'color:red', error) // eslint-disable-line no-console
      this.showError(this.t('deleteOverride.error'))
    } finally {
      this.pending = false
    }
  }
}
