<template>
  <v-dialog
    v-model="show"
    persistent
    width="440"
    @keydown.esc="close"
  >
    <v-card
    >
      <v-card-title>
        <div>
          <h3>{{ $t('components.newFeatures.dialog.title') }}</h3>
        </div>
        <v-spacer/>
        <v-btn
          icon
          small
          @click="close"
        >
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        v-html="$t('components.newFeatures.dialog.content')"
      />
      <v-card-text>
        <v-checkbox
          v-model="showNotAgain"
          :label="$t('components.newFeatures.dialog.action.doNotShowAgain')"
          dense
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="close"
          color="error"
          role="button"
          text
        >
          {{ $t('components.newFeatures.dialog.action.close') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          @click="closeAndNext"
          color="primary"
          role="button"
        >
          {{ $t('components.newFeatures.dialog.action.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Component, VModel, Vue } from 'vue-property-decorator'

@Component
export default class NewFeaturesDialog extends Vue {
  @VModel({ type: Boolean }) show

  showNotAgain = false

  close () {
    if (this.showNotAgain) {
      this.$emit('disable-updates')
    }
    this.show = false
  }

  closeAndNext () {
    this.$emit('show-feature-updates')
    if (this.showNotAgain) {
      this.$emit('disable-updates')
    }
    this.close()
    window.open(this.$t('components.navigation.sidebar.productUpdate.url'), '_blank')
  }
}
</script>
