<template>
  <span>
  <v-chip
    v-if="isEditMode"
    :style="styles"
    class="trend-tag"
    close
    dark
    label
    @click:close="onClose"
  >
    {{ tag.title }}
        <deprecation-tooltip
          v-if="isDeprecatedMacrotrendMacrotrend"
          :text="tooltipText"
          auto-color
        />
  </v-chip>
  <v-chip
    v-else
    :style="styles"
    class="trend-tag"
    dark
    label
  >
    {{ tag.title }}
    <deprecation-tooltip
      v-if="isDeprecatedMacrotrendMacrotrend"
      :text="tooltipText"
      auto-color
    />
  </v-chip>
    </span>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { MACROTREND } from '@/model/types'
import DeprecationTooltip from '@/components/macrotrend/deprecation-tooltip/deprecation-tooltip'

@Component({
  components: {
    DeprecationTooltip
  }
})
export default class FacetTag extends Vue {
  @Prop({
    type: Object
  }) tag

  @Prop({
    type: Boolean,
    default: () => true
  }) isEditMode

  @Prop({
    type: Boolean,
    default: false
  }) isAdmin

  get isDeprecatedMacrotrendMacrotrend () {
    return this.tag.type === MACROTREND && this.tag.deprecated
  }

  get styles () {
    return {
      'background-color': this.tag.color
    }
  }

  get tooltipText () {
    const key = this.isAdmin ? 'tooltipAdmin' : 'tooltipUser'
    const successorTitle = this.tag.successor?.translations[0]?.title
    const successorText = this.isAdmin && successorTitle
      ? this.t('tooltipSuccessor', { title: successorTitle })
      : ''

    return this.t(key) + successorText
  }

  t (key, props) {
    return this.$t(`components.macrotrend.macrotrendDeprecatedTooltip.${key}`, props)
  }

  onClose () {
    if (this.tag.parent) {
      this.$emit('removeChild', this.tag)
    } else {
      this.$emit('removeParent', this.tag)
    }
  }
}
</script>
