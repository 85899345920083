<template>
  <div
    id="layout-admin"
  >
    <navigation-admin/>
    <v-main>
      <router-view/>
    </v-main>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import NavigationAdmin from '@/components/navigation/admin/index.vue'

@Component({
  components: {
    NavigationAdmin
  }
})
export default class AdminLayout extends Vue {
}
</script>
