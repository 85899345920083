export const GET_DETAILS_SHOW = 'GET_DETAILS_SHOW'
export const GET_DETAILS = 'GET_DETAILS'
export const GET_HIGHLIGHT = 'GET_HIGHLIGHT'
export const GET_MODE = 'GET_MODE'
export const GET_RELOAD_POSTS_CNT = 'GET_RELOAD_POSTS_CNT'
export const GET_RELOAD_POSTS_TYPE = 'GET_RELOAD_POSTS_TYPE'
export const GET_VIEW = 'GET_VIEW'
export const GET_CHART_CONFIGURATION = 'GET_CHART_CONFIGURATION'
export const GET_CRITERIAS = 'GET_CRITERIAS'
export const GET_CRITERIA_SET = 'GET_CRITERIA_SET'
export const GET_FILTER = 'GET_FILTER'
