
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import UniverseUpdatesDialog from '@/components/universe-updates/universe-updates-dialog.vue'
import { namespace } from 'vuex-class'
import { GET_LOCALE, ROLES } from '@/store/auth/getter-types'
import { SET, SetFlag } from '@/store/flag/mutation-types'
import { Locale, UiQuery, useUiQuery } from '@/graphql/app/types'
import { GET, GetFlag } from '@/store/flag/getter-types'
import { GET_SHOW_UPDATE_DIALOG } from '@/store/gui/getter-types'
import { SET_SHOW_UPDATE_DIALOG } from '@/store/gui/mutation-types'
import { HAS_SEEN_UNIVERSE_DIALOG, UI } from '@/model/flags'
import { TRENDONE_MEGATREND } from '@/model/types'

const authStore = namespace('auth')
const flagStore = namespace('flag')
const guiStore = namespace('gui')

@Component<UniverseUpdates>({
  components: { UniverseUpdatesDialog },
  apollo: {
    showUniverseUpdate: useUiQuery<UniverseUpdates>({
      update: (data: UiQuery) => data.ui.showUniverseUpdate,
      skip () {
        return this.flag
      }
    })
  }
})
export default class UniverseUpdates extends Vue {
  @authStore.Getter(GET_LOCALE) locale!: Locale
  @authStore.Getter(ROLES) roles!: string[]
  @flagStore.Mutation(SET) setFlag!: SetFlag
  @flagStore.Getter(GET) getFlag!: GetFlag
  @guiStore.Getter(GET_SHOW_UPDATE_DIALOG) showUpdateDialogOverride!: boolean
  @guiStore.Mutation(SET_SHOW_UPDATE_DIALOG) setShowUpdateDialogOverride!: (payload: boolean) => void

  private showUniverseUpdate = false

  get flag (): boolean {
    return this.getFlag({
      type: UI,
      name: HAS_SEEN_UNIVERSE_DIALOG
    }) || false
  }

  get isAdmin (): boolean {
    // @ts-ignore
    return this.$can('manage', TRENDONE_MEGATREND)
  }

  get showDialog (): boolean {
    return (this.showUniverseUpdate && !this.flag) || this.showUpdateDialogOverride
  }

  onClose (): void {
    this.setFlag({
      type: UI,
      name: HAS_SEEN_UNIVERSE_DIALOG,
      value: true
    })
    this.setShowUpdateDialogOverride(false)
  }

  async onGoToAdmin (): Promise<void> {
    await this.$router.push({ name: 'admin-trendone-universe' })
  }
}
