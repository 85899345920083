<template>
  <v-list-item
  >
    <v-list-item-action
      v-if="!addOnly"
    >
      <v-checkbox
        color="primary"
        v-model="checked"
        :indeterminate="indeterminate"
        @click.stop.prevent="checked = !checked"
      />
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>
        {{ megatrend.title }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TrendTreeMegatrendElement extends Vue {
  @Prop({
    type: Boolean,
    default: () => false
  }) selected

  @Prop({
    type: Boolean,
    default: () => false
  }) addOnly

  @Prop({
    type: Object,
    required: true
  }) megatrend

  set checked (value) {
    return value
      ? this.$emit('add', this.megatrend)
      : this.$emit('remove', this.megatrend)
  }

  get checked () {
    return this.selected !== false
  }

  get indeterminate () {
    return this.selected === null
  }
}
</script>
