import { Component, Vue } from 'vue-property-decorator'
import { COOKIE_CONSENT, MATOMO, TRACKING_CONSENT } from '@/model/flags'
import { tracker } from '@/plugins/tracker'
import { namespace } from 'vuex-class'
import { GET, GetFlag } from '@/store/flag/getter-types'
import { SET, SetFlag } from '@/store/flag/mutation-types'

const flagStore = namespace('flag')

@Component
export default class ConsentMixin extends Vue {
  @flagStore.Getter(GET) getFlag!: GetFlag
  @flagStore.Mutation(SET) setFlag!: SetFlag

  get matomoCookieConsent (): boolean | undefined {
    return this.getFlag({
      type: COOKIE_CONSENT,
      name: MATOMO
    })
  }

  get matomoTrackingConsent (): boolean | undefined {
    return this.getFlag({
      type: TRACKING_CONSENT,
      name: MATOMO
    })
  }

  setConsents (): void {
    // Order is important, because tracking-consent overrides cookie-consent
    tracker.setTrackingConsentGiven(this.matomoTrackingConsent || false)
    tracker.setCookieConsentGiven(this.matomoCookieConsent || false)
  }
}
