export const emptyInput = () => ({
  title: null,
  description: null,
  image: null,
  contactPersonId: null,
  macrotrends: [],
  trendRadars: []
})

export const empty = () => ({
  createdNew: false,
  delete: false,
  edit: false,
  id: null,
  input: emptyInput()
})

export const state = empty()
