import * as Getter from './getter-types'

export default {
  [Getter.GET_DELETE_DATA]: (state) => {
    return {
      id: state.id,
      title: state.input.title
    }
  },

  [Getter.INNOVATION_FIELD_INPUT]: (state) => state.input,

  [Getter.INNOVATION_FIELD_ID]: (state) => state.id,

  [Getter.SHOW_DELETE_DIALOG]: (state) => state.delete,

  [Getter.SHOW_EDIT_DIALOG]: (state) => state.edit,

  [Getter.GET_CREATED_NEW]: (state) => state.createdNew
}
