export const INCREMENT_RELOAD_POST_CNT = 'INCREMENT_RELOAD_POST_CNT'
export const RESET_DETAILS = 'RESET_DETAILS'
export const RESET_RELOAD_POSTS_CNT = 'RESET_RELOAD_POSTS_CNT'
export const SET_DETAILS = 'SET_DETAILS'
export const SET_HIGHLIGHT = 'SET_HIGHLIGHT'
export const SET_MODE = 'SET_MODE'
export const CHANGE_VIEW = 'CHANGE_VIEW'
export const CHANGE_CHART_CONFIGURATION = 'CHANGE_CHART_CONFIGURATION'
export const INIT_CHART_CONFIGURATION = 'INIT_CHART_CONFIGURATION'
export const SET_FILTER = 'SET_FILTER'
