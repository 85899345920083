
import { Component, Vue } from 'vue-property-decorator'
import ToIcon from '@/components/to-icon/to-icon.vue'

@Component({
  components: {
    ToIcon
  }
})
export default class NavigationAdminSidebar extends Vue {
  i18n (token: string): string {
    return this.$t(`components.navigation.admin.${token}`).toString()
  }

  onLogout () {
    this.$emit('logout')
  }
}
