import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import introspectionQueryResultData from './fragmentTypes.json'
import store from '@/store'
import { runtimeConfig } from '@/util/runtime-config'
import { extractNetworkError } from '@/error-handler/network'
import { showErrors, showErrorsIfExpired } from '@/error-handler/snackbar'
import { redirectToLogin } from '@/error-handler/logout'
import { onError } from 'apollo-link-error'
import { namespacer } from '@/util/namespacer'
import { GET_LOCALE, IS_AUTHENTICATED } from '@/store/auth/getter-types'
import { extractGraphQLErrors } from '@/error-handler/graphql'
// import { Observable } from 'apollo-link'
import { LOGOUT } from '@/store/auth/action-types'
import { includesAuthError } from '@/error-handler/detect-auth-error'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const createApolloClient = (uri) => {
  const addHeadersLink = setContext((_, { headers }) => {
    if (store.getters[namespacer('auth', IS_AUTHENTICATED)]) {
      return {
        headers: {
          ...headers,
          'x-accept-language': store.getters[namespacer('auth', GET_LOCALE)]
        }
      }
    } else {
      return headers
    }
  })

  const uploadLink = addHeadersLink.concat(createUploadLink({
    uri,
    credentials: 'include'
  }))

  const errorLink = onError(({ networkError, graphQLErrors, operation, forward }) => {
    const catchedErrors = [].concat(extractNetworkError(networkError), extractGraphQLErrors(graphQLErrors))
    if (catchedErrors.length > 0) {
      if (includesAuthError(catchedErrors)) {
        showErrorsIfExpired(catchedErrors, 36000)
        redirectToLogin()
        store.dispatch(namespacer('auth', LOGOUT), true)
      } else {
        showErrors(catchedErrors)
      }
    }
  })

  return new ApolloClient({
    link: errorLink.concat(uploadLink),
    cache: new InMemoryCache({ fragmentMatcher }),
    // eslint-disable-next-line no-undef
    connectToDevTools: process.env.NODE_ENV !== 'production'
  })
}

// eslint-disable-next-line no-undef
export const appClient = createApolloClient(runtimeConfig('VUE_APP_BASE_URI') + '/graphql/app')
// eslint-disable-next-line no-undef
export const anonClient = createApolloClient(runtimeConfig('VUE_APP_BASE_URI') + '/graphql/anon')
// eslint-disable-next-line no-undef
export const adminClient = createApolloClient(runtimeConfig('VUE_APP_BASE_URI') + '/graphql/companyAdmin')

Vue.use(VueApollo)

export const apolloProvider = new VueApollo({
  clients: {
    app: appClient,
    admin: adminClient,
    anon: anonClient
  },
  defaultClient: appClient,
  defaultOptions: {
    $query: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all'
    }
  }
})

/*
TODO: Test the following, newer version
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import fetch from 'isomorphic-unfetch'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import introspectionQueryResultData from './fragmentTypes.json'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from 'apollo-link-error'

import i18n from '@/plugins/i18n'
import store from '@/store'
import { namespacer } from '@/util/namespacer'
import * as util from './util'

import { LOGOUT } from '@/store/auth/action-types'
import { SHOW_SNACKBAR } from '@/store/gui/mutation-types'
import { TOKEN_EXPIRED } from '@/store/auth/getter-types'

Vue.use(VueApollo)

export const ANON = 'anon'
export const APP = 'app'
export const COMPANY_ADMIN = 'companyAdmin'

const i18nPath = 'graphql.errorCodes'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const showSnackBar = (message) => {
  const options = {
    messages: Array.isArray(message) ? message : [message],
    mode: 'error',
    timeout: 60 * 1000
  }
  store.commit(namespacer('gui', SHOW_SNACKBAR), options)
}

const showSnackBarIfNeeded = (message) => {
  if (store.getters[namespacer('auth', TOKEN_EXPIRED)]()) {
    showSnackBar(message)
  }
}

const errorLink = onError(({
  graphQLErrors,
  networkError,
  operation
}) => {
  const { response } = operation.getContext()
  if (response.status === 401) {
    store.dispatch(namespacer('auth', LOGOUT), true)
    util.redirectToLogin()
    showSnackBarIfNeeded(i18n.t(`${i18nPath}.401}`))
  } else {
    if (graphQLErrors) {
      const messages = graphQLErrors.map(({ code }) => util.mapErrorcodeToMessage(code, i18nPath))
      if (messages.length) {
        showSnackBar(messages)
      }
    }

    if (networkError) {
      showSnackBar(networkError.message)
    }
  }
})

const createApolloClient = (endpoint) => {
  const options = {
    uri: `${process.env.VUE_APP_BASE_URI}/graphql/${endpoint}`,
    credentials: 'include',
    fetch: fetch
  }
  const httpLink = createUploadLink(options)

  return new ApolloClient({
    link: errorLink.concat(httpLink),
    cache: new InMemoryCache({ fragmentMatcher }),
    connectToDevTools: true
  })
}

export const appClient = createApolloClient(APP)
export const anonClient = createApolloClient(ANON)
export const adminClient = createApolloClient(COMPANY_ADMIN)

export const apolloProvider = new VueApollo({
  defaultClient: appClient,
  clients: {
    [APP]: appClient,
    [ANON]: anonClient,
    [COMPANY_ADMIN]: adminClient
  }
})

export const reset = async () => {
  try {
    await appClient.resetStore()
    await anonClient.resetStore()
    await adminClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'colors: red;', e.message)
  }
}
*/
