import Vue from 'vue'
import { router } from '@/plugins/router'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { runtimeConfig } from '@/util/runtime-config'

const DSN = runtimeConfig('VUE_APP_SENTRY_DSN', '')
if (DSN.length > 0) {
  Sentry.init({
    Vue,
    dsn: DSN,
    sampleRate: 1.0,
    tracesSampleRate: 0.4,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [runtimeConfig('VUE_APP_SENTRY_TARGET', 'localhost'), /^\//]
      })
    ]
  })
}
