import { Ability, defineAbility } from '@casl/ability'

import * as ROLE from '@/model/roles'
import * as FEATURE from '@/model/views/features'
import * as TYPE from '@/model/types'
import { PRODUCT } from '@/model/products'

const companyHasFeature = (user, feature) => {
  return user.company.features.includes(feature)
}

const isTrendexplorerProduct = (user) => {
  return !(user && user.company && user.company.product && user.company.product !== PRODUCT.TRENDEXPLORER)
}

const createUserPermissions = (currentUser, can, cannot) => {
  can('use-feature', currentUser.company.features)

  can('read', 'all')
  cannot('read', [
    TYPE.WORKSPACE,
    TYPE.SEARCH_AGENT,
    TYPE.TRENDRADAR_PUBLISHED,
    TYPE.INSIDER,
    TYPE.COMPANY_MACROTREND,
    TYPE.COMPANY_MEGATREND,
    TYPE.COMPANY_MICROTREND,
    TYPE.INNOVATION_PROJECT
  ])

  if (companyHasFeature(currentUser, FEATURE.COMPANY_MACRO_MEGA_TREND)) {
    can('read', ['CompanyMegatrend', 'CompanyMacrotrend'])
  }
  if (companyHasFeature(currentUser, FEATURE.COMPANY_MICRO_TREND)) {
    can('create', TYPE.COMPANY_MICROTREND)
    can(['delete', 'update'], TYPE.COMPANY_MICROTREND, { 'author.id': currentUser.id })
  }
  if (companyHasFeature(currentUser, FEATURE.WORKSPACE)) {
    can('create', TYPE.WORKSPACE)
    can('manage', TYPE.WORKSPACE, {
      'participants.nodes': { $elemMatch: { id: { $eq: currentUser.id } } }
    })
  }
  if (companyHasFeature(currentUser, FEATURE.SEARCH_AGENT)) {
    can('manage', TYPE.SEARCH_AGENT)
  }

  if (companyHasFeature(currentUser, FEATURE.TRENDRADAR) || isTrendexplorerProduct(currentUser)) {
    can('read', TYPE.TRENDRADAR_PUBLISHED)
  }

  if (currentUser.company.features.includes(FEATURE.INSIDER)) {
    can('manage', TYPE.INSIDER)
  }

  if (companyHasFeature(currentUser, FEATURE.INNOVATION_PROJECT)) {
    can('read', TYPE.INNOVATION_PROJECT)
    can('update', TYPE.INNOVATION_PROJECT, {
      participants: { $elemMatch: { id: { $eq: currentUser.id } } }
    })
  }

  can(['delete', 'update'], ['Comment', 'Post'], { 'owner.id': currentUser.id })

  if (companyHasFeature(currentUser, FEATURE.PINS)) {
    can('create', TYPE.MICROTREND_PIN)
    can('delete', TYPE.MICROTREND_PIN, { 'pinnedBy.id': currentUser.id })
  }

  if (currentUser.company.testAccount) {
    can('read', TYPE.TRENDRADAR_PUBLISHED)
  }
}

const createTrendmanagerPermissions = (currentUser, can) => {
  can('manage', [
    TYPE.MACROTREND
  ])

  if (companyHasFeature(currentUser, FEATURE.INNOVATION_PROJECT)) {
    can('manage', TYPE.INNOVATION_PROJECT)
  }

  if (companyHasFeature(currentUser, FEATURE.COMPANY_MACRO_MEGA_TREND)) {
    can('manage', [
      TYPE.COMPANY_MEGATREND,
      TYPE.COMPANY_MACROTREND
    ])
  }

  if (companyHasFeature(currentUser, FEATURE.PINS)) {
    can('manage', TYPE.MICROTREND_PIN)
    can('delete', TYPE.MICROTREND_PIN)
  }

  if (companyHasFeature(currentUser, FEATURE.COMPANY_MICRO_TREND)) {
    can('manage', TYPE.COMPANY_MICROTREND)
  }

  if (companyHasFeature(currentUser, FEATURE.INNOVATION_FIELD)) {
    can('manage', TYPE.INNOVATION_FIELD)
  }

  if (companyHasFeature(currentUser, FEATURE.TRENDRADAR)) {
    can(['update', 'delete'], TYPE.TRENDRADARS, {
      managers: { $elemMatch: { id: { $eq: currentUser.id } } }
    })
    can('create', TYPE.TRENDRADAR_DRAFT)
  }

  can('update', TYPE.MACROTREND)
  can('delete', [TYPE.COMMENT, TYPE.POST])

  if (currentUser.company.features.includes(FEATURE.UNIVERSE_ADMIN) && !isTrendexplorerProduct(currentUser)) {
    can('manage', TYPE.TRENDONE_TRENDS)
  }
}

const createCompanyAdminPermissions = (currentUser, can, cannot) => {
  can('manage', 'User')
  can('lock', 'User')

  cannot('lock', 'User', { id: currentUser.id })
  cannot('lock', 'User', { roles: ROLE.ROLE_ADMIN })
  cannot('lock', 'User', { roles: ROLE.ROLE_SUPER_ADMIN })

  if (companyHasFeature(currentUser, FEATURE.TRENDRADAR)) {
    can(['update', 'delete'], TYPE.TRENDRADARS)
  }
}

export const createRulesByUser = currentUser => {
  return defineAbility((can, cannot) => {
    if (Array.isArray(currentUser.roles)) {
      if (
        currentUser.roles.includes(ROLE.ROLE_ADMIN) ||
        currentUser.roles.includes(ROLE.ROLE_SUPER_ADMIN)
      ) {
        createCompanyAdminPermissions(currentUser, can, cannot)
        createTrendmanagerPermissions(currentUser, can)
        createUserPermissions(currentUser, can, cannot)
      } else if (currentUser.roles.includes(ROLE.ROLE_TREND_MANAGER)) {
        createTrendmanagerPermissions(currentUser, can)
        createUserPermissions(currentUser, can, cannot)
      } else if (currentUser.roles.includes(ROLE.ROLE_USER)) {
        createUserPermissions(currentUser, can, cannot)
      } else {
        cannot('manage', 'all')
      }
    } else {
      cannot('manage', 'all')
    }
  }).rules
}

export const ability = new Ability([], {
  detectSubjectType: item => item && typeof item === 'object' ? item.__typename : item
})
