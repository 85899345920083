<template>
  <span>
    <v-chip
      v-if="value"
      :close="isEditMode"
      :style="{'background-color': '#4A4848'}"
      class="trend-tag"
      dark
      label
      text-color="white"
      @click:close="onClose"
    >
      {{ value }}
    </v-chip>
  </span>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SearchTag extends Vue {
  @Prop({
    type: String,
    default: () => null
  }) value

  @Prop({
    type: Boolean,
    default: () => true
  }) isEditMode

  onClose () {
    this.$emit('input', null)
  }
}
</script>
