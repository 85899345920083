
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import TrendTreeLoader from '@/components/trend-tree/trend-tree-loader.vue'
import TrendTreeUi from '@/components/trend-tree/trend-tree-ui.vue'
import { MacrotrendInterface } from '@/graphql/app/types'

@Component({
  components: {
    TrendTreeUi,
    TrendTreeLoader
  }
})
export default class TrendTree extends Vue {
  @VModel() macrotrends!: Array<MacrotrendInterface>
  @Prop({ type: Boolean, default: () => false }) addOnly!: boolean
  @Prop({ type: Boolean, default: () => false }) showTags!: boolean
}
