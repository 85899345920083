import * as ACTION from './action-types'
import * as MUTATION from './mutation-types'
import * as service from '@/service/trendradar'
import { track } from '@/plugins/tracker'

const isEvaluated = (evaluation) => { return evaluation.evaluation !== null }

export default {
  [ACTION.EVALUATE]: async ({ commit, rootState }, payload) => {
    try {
      commit(MUTATION.SET_PENDING, true)
      const participation = await service.evaluate(
        payload.trendradarId, payload.macrotrendId, payload.values, payload.locale
      )
      commit(MUTATION.UPDATE_PARTICIPATION, participation)
      commit(MUTATION.SET_PARTICIPATION_COMPLETED, participation.evaluations.every(isEvaluated))
      track('Trendradar', 'vote')
    } finally {
      commit(MUTATION.SET_PENDING, false)
    }
  },
  [ACTION.LOAD_PARTICIPATION]: async ({ commit, rootState }, payload) => {
    commit(MUTATION.SET_LOADING, true)
    commit(MUTATION.UPDATE_PARTICIPATION, await service.loadParticipation(payload, rootState.auth.locale))
    commit(MUTATION.SET_LOADING, false)
  }
}
