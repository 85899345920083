
import { Component, Prop, Vue } from 'vue-property-decorator'
import { User, useUserSelectQuery } from '@/graphql/app/types'

@Component({
  apollo: {
    users: useUserSelectQuery({
      fetchPolicy: 'network-only'
    })
  }
})
export default class UserSelectLoader extends Vue {
  @Prop({ default: () => '' }) avoidMeId!: string

  users: Array<User> = []

  get filtered (): Array<User> {
    return this.avoidMeId.length === 0
      ? this.users
      : this.users.filter(elem => elem.id !== this.avoidMeId)
  }

  get loading (): boolean {
    return this.$apollo.queries.users.loading
  }
}
