import store from '@/store'
import { namespacer } from '@/util/namespacer'
import { IS_AUTHENTICATED } from '@/store/auth/getter-types'

export const authentication = async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters[namespacer('auth', IS_AUTHENTICATED)]) {
      next()
    } else {
      store.dispatch('auth/logout', true)
      const target = {
        redirect: to.fullPath
      }
      if (to.query.q) {
        target.q = to.query.q
      }

      next({
        name: 'login',
        query: target
      })
    }
  } else {
    next()
  }
}
