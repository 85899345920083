<template>
  <v-text-field
    v-if="adHoc"
    v-model="internal"
    :label="label"
    hide-details
    :loading="loading"
    filled
  />
  <v-text-field
    v-else
    v-model="copy"
    :label="label"
    hide-details
    append-icon="mdi-magnify"
    :append-icon-cb="accept"
    @keyup.enter="accept"
    :loading="loading"
    filled
  />
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class CompanyMacrotrendSearchQuery extends Vue {
  @Prop({
    type: String,
    default: () => null
  }) value

  @Prop({
    type: String,
    default: () => null
  }) label

  @Prop({
    type: Boolean,
    default: () => false
  }) adHoc

  @Prop({
    type: Boolean,
    default: () => false
  }) loading

  copy = null

  get internal () {
    return this.value
  }

  set internal (value) {
    this.$emit('input', value)
  }

  get icon () {
    return this.adHoc ? 'search' : null
  }

  accept () {
    this.internal = this.copy
  }

  @Watch('value')
  updateCopy (value) {
    if (!this.adHoc) {
      this.copy = value
    }
  }

  mounted () {
    this.updateCopy()
  }
}
</script>
