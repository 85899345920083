import store from '@/store'
import { IS_AUTHENTICATED } from '@/store/auth/getter-types'
import { UPDATE_RULES } from '@/store/auth/action-types'
import { namespacer } from '@/util/namespacer'

export const updateAbilities = async (to, from, next) => {
  try {
    const authenticated = store.getters[namespacer('auth', IS_AUTHENTICATED)]
    const sessionStart = to.name === 'login'

    if (authenticated && !sessionStart) {
      await store.dispatch(namespacer('auth', UPDATE_RULES))
    }
  } catch (error) {
    console.log('%cError update-abilities hook: ', 'color:red', error) // eslint-disable-line no-console
  } finally {
    next()
  }
}
