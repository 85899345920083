<template>
  <div class="wrap pb-2">
    <navigation-sidebar
      v-model="drawer"
      :mobile="isMobile"
    />
    <header class="header">
      <navigation-header
        v-model="drawer"
        :mobile="isMobile"
      />
      <portal-target name="sidebar"/>
    </header>
    <v-main>
      <v-fade-transition
        mode="out-in"
      >
        <router-view/>
      </v-fade-transition>
    </v-main>
    <global-dialogs/>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { isMobile } from 'mobile-device-detect'

import GlobalDialogs from '@/components/layout/global-dialogs'
import NavigationHeader from '@/components/navigation/header/header'
import NavigationSidebar from '@/components/navigation/sidebar/sidebar'

@Component({
  components: {
    GlobalDialogs,
    NavigationHeader,
    NavigationSidebar
  }
})
export default class MenuLayout extends Vue {
  drawer = false

  get isMobile () {
    return isMobile
  }
}
</script>
