import store from '@/store'
import {
  COMPANY_MACRO_MEGA_TREND,
  COMPANY_MICRO_TREND,
  INNOVATION_FIELD,
  INNOVATION_PROJECT,
  INSIGHTS,
  SEARCH_AGENT,
  TRENDRADAR,
  WORKSPACE
} from '@/model/views/features'
import { IS_FEATURE_ENABLED, IS_TRENDEXPLORER_COMPANY } from '@/store/auth/getter-types'
import { namespacer } from '@/util/namespacer'

function companyIsAuthorized (name, checkTXCompanies) {
  if (name.indexOf('insight') !== -1) {
    return store.getters[namespacer('auth', IS_FEATURE_ENABLED)](INSIGHTS)
  }

  if (name.indexOf('trendradar') !== -1) {
    const featureEnabled = store.getters[namespacer('auth', IS_FEATURE_ENABLED)](TRENDRADAR)

    if (!checkTXCompanies) {
      return featureEnabled
    }

    // Certain trendradar-routes should also be accessible for test-companies without according feature
    return store.getters[namespacer('auth', IS_TRENDEXPLORER_COMPANY)] || featureEnabled
  }

  if (name.indexOf('workspace') !== -1) {
    return store.getters[namespacer('auth', IS_FEATURE_ENABLED)](WORKSPACE)
  }

  if (name.indexOf('search-agent') !== -1) {
    return store.getters[namespacer('auth', IS_FEATURE_ENABLED)](SEARCH_AGENT)
  }

  if (name.indexOf('company-microtrend') !== -1) {
    return store.getters[namespacer('auth', IS_FEATURE_ENABLED)](COMPANY_MICRO_TREND)
  }

  if (name.indexOf('innovation-field') !== -1) {
    return store.getters[namespacer('auth', IS_FEATURE_ENABLED)](INNOVATION_FIELD)
  }

  if (name.indexOf('admin-company-trend') !== -1) {
    return store.getters[namespacer('auth', IS_FEATURE_ENABLED)](COMPANY_MACRO_MEGA_TREND)
  }

  if (name.indexOf('innovation-project') !== -1) {
    return store.getters[namespacer('auth', IS_FEATURE_ENABLED)](INNOVATION_PROJECT)
  }

  return true
}

export const authorizationCheck = (to, from, next) => {
  if (companyIsAuthorized(to.name, to.meta.authorizeTXCompany)) {
    next()
  } else {
    next({
      name: 'missing-feature-redirect',
      params: {
        code: '403',
        message: 'Sie verfügen nicht über die erforderlichen Rechte, um diese Seite aufzurufen.'
      }
    })
  }
}
