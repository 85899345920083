
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'
import Editor from '@/components/form/editor/editor.vue'
import UserSelect from '@/components/form/user-select/index.vue'
import { WorkspaceInput } from '@/graphql/app/types'

@Component({
  components: {
    Editor,
    UserSelect
  },
  validations: {
    workspace: {
      title: {
        required
      },
      description: {},
      participantIds: {}
    }
  }
})
export default class WorkspaceForm extends Vue {
  @VModel() workspace!: WorkspaceInput

  @Prop({ default: () => false }) pending!: boolean
  @Prop({ type: Boolean, default: () => false }) participantsOnly!: boolean

  close (): void {
    this.$emit('close')
    this.$v.$reset()
  }

  onSubmit (): void {
    this.$emit('submit')
    this.$v.$reset()
  }

  get hasErrors (): boolean {
    return this.$v.$invalid
  }

  get titleErrors (): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.workspace.title!.$dirty) {
      return errors
    }
    !this.$v.workspace.title!.required && errors.push(this.t('title.error'))

    return errors
  }

  /*
  get descriptionErrors (): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.workspace.description.$dirty) {
      return errors
    }
    !this.$v.workspace.description.required && errors.push(
      this.t('description.error')
    )

    return errors
  }
  */

  t (token: string, props: Record<string, any> = {}, cnt: number | null = null) {
    const prefix = 'components.workspace.form'
    return cnt === null
      ? this.$t(`${prefix}.${token}`, props).toString()
      : this.$tc(`${prefix}.${token}`, cnt, props).toString()
  }
}
