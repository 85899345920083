import store from '@/store'
import { hasRole } from '@/model/roles'
import { namespacer } from '@/util/namespacer'
import { ROLES } from '@/store/auth/getter-types'
import { LOGOUT } from '@/store/auth/action-types'

export const roleCheck = (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresRole)) {
    let requiredRole = null
    to.matched.forEach(elem => {
      if (elem.meta && elem.meta.requiresRole) {
        requiredRole = elem.meta.requiresRole
      }
    })

    if (hasRole(store.getters[namespacer('auth', ROLES)], requiredRole)) {
      next()
    } else {
      store.dispatch(namespacer('auth', LOGOUT)).then(() => {
        next({
          name: 'error',
          params: {
            code: '403',
            message: 'Sie verfügen nicht über die erforderlichen Rechte, um diese Seite aufzurufen.'
          }
        })
      })
    }
  } else {
    next()
  }
}
