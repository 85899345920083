export const createFreshState = () => ({
  facets: {},
  query: null,
  companyTrendsOnly: {
    microtrends: false
  },
  trendoneTrendsOnly: {
    microtrends: false
  },
  trendsWithUseCasesOnly: false,
  sidebar: false
})

const state = createFreshState()
export default state
