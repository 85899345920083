<template>
  <v-tooltip
    v-if="disabled"
    color="primary"
    fixed
    top
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
      >
        <v-chip
          :style="styles"
          class="trend-tag"
          dark
          disabled
          label
          small
        >
          {{ trend.translations[0].title }}
        </v-chip>
      </div>
    </template>
    <span>{{ $t('components.form.editableTrendTag.disabled') }}</span>
  </v-tooltip>
  <v-chip
    v-else
    :style="styles"
    class="trend-tag"
    dark
    label
    close
    small
    @click:close="onInput"
  >
    {{ trend.translations[0].title }}
  </v-chip>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { COMPANY_MACROTREND, COMPANY_MEGATREND, MACROTREND, MACROTRENDS, MEGATREND, MEGATRENDS } from '@/model/types'

const TYPES = [
  ...MACROTRENDS,
  ...MEGATRENDS
]

@Component
export default class EditableTrendTag extends Vue {
  @Prop({
    type: Object,
    validator: obj => TYPES.includes(obj.__typename),
    required: true
  }) trend

  @Prop({
    type: Boolean,
    default: () => false
  }) disabled

  get color () {
    switch (this.trend.__typename) {
    case COMPANY_MEGATREND:
    case MEGATREND:
      return this.trend.color
    case COMPANY_MACROTREND:
    case MACROTREND:
      return this.trend.megatrend.color
    }
    return 'red'
  }

  get styles () {
    return {
      'background-color': this.color,
      color: 'white',
      cursor: 'not-allowed'
    }
  }

  onInput () {
    this.$emit('remove', this.trend.id)
  }
}
</script>
