<template>
  <v-dialog
    v-model="showDialog"
    max-width="600px"
    scrollable
  >
    <form @submit.prevent="onSubmit">
      <v-card
        tile
      >
        <v-card-title>
          <div>
            <h3>{{ $t('components.txMigration.feedback.form.title') }}</h3>
          </div>
          <v-spacer/>
          <v-btn
            icon
            small
            @click="hide"
          >
            <v-icon
              small
            >
              mdi-close
            </v-icon>
          </v-btn>
          <div class="text-caption mt-3">
            <p>{{ $t('components.txMigration.feedback.form.caption.opener') }}</p>
            <p>{{ $t('components.txMigration.feedback.form.caption.text') }}</p>
            <p>{{ $t('components.txMigration.feedback.form.caption.signature') }}</p>
          </div>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="$v.feedback.subject.$model"
            :placeholder="$t('components.txMigration.feedback.form.subject')"
            filled
          />
          <v-textarea
            v-model="$v.feedback.feedback.$model"
            :error-messages="feedbackErrors"
            :placeholder="$t('components.txMigration.feedback.form.feedback')"
            filled
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            :disabled="$v.feedback.$invalid || pending"
            :loading="pending"
            class="float-right"
            color="primary"
            type="submit"
          >
            {{ $t('components.txMigration.feedback.form.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { GET_CREATE_FEEDBACK } from '@/store/gui/getter-types'
import { HIDE_CREATE_FEEDBACK, SHOW_SNACKBAR } from '@/store/gui/mutation-types'
import { required } from 'vuelidate/lib/validators'
import sendFeedbackGQL from '@/graphql/app/tx-migration/mutation/send-feedback.gql'

const gui = namespace('gui')

const initFeedback = () => ({
  subject: '',
  feedback: ''
})

@Component({
  validations: {
    feedback: {
      subject: {},
      feedback: {
        required
      }
    }
  }
})
export default class FeedbackDialog extends Vue {
  @gui.Getter(GET_CREATE_FEEDBACK) show
  @gui.Mutation(HIDE_CREATE_FEEDBACK) hide
  @gui.Mutation(SHOW_SNACKBAR) showSnackbar

  pending = false
  isFormValid = false

  feedback = initFeedback()

  get showDialog () {
    return this.show
  }

  set showDialog (_) {
    return this.hide()
  }

  get feedbackErrors () {
    const errors = []

    this.$v.feedback.feedback.$dirty && !this.$v.feedback.feedback.required && errors.push(
      this.$t('components.txMigration.feedback.form.validation.feedback')
    )

    return errors
  }

  reset () {
    this.feedback = initFeedback()
    this.$v.$reset()
  }

  async onSubmit () {
    try {
      this.pending = true
      await this.$apollo.mutate({
        mutation: sendFeedbackGQL,
        variables: {
          input: {
            subject: this.feedback.subject,
            message: this.feedback.feedback
          }
        }
      })
      this.showSnackbar(this.$t('components.txMigration.feedback.create.success'))
      this.hide()
      this.reset()
    } catch (error) {
      console.log('%cError on sending feedback: ', 'color:red', error) // eslint-disable-line no-console
    } finally {
      this.pending = false
    }
  }
}
</script>
