import * as MUTATION from './mutation-types'

import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import { createFreshState } from './state'

export default {
  [MUTATION.INIT]: (state, payload) => {
    const copy = cloneDeep(payload)
    Vue.set(state, 'query', copy.query)
    Vue.set(state, 'facets', copy.facets !== null ? copy.facets : {})
  },

  [MUTATION.ADD_ENTRY]: (state, payload) => {
    Vue.set(state.facets, payload.id, cloneDeep(payload))
  },

  [MUTATION.REMOVE_ENTRY]: (state, payload) => {
    Vue.delete(state.facets, payload.id)
    delete state.facets[payload.id]

    if (payload.parent && state.facets[payload.parent]) {
      const parent = state.facets[payload.parent]
      let cnt = 0
      Object.keys(parent.children).map(key => {
        cnt += (state.facets[key] ? 1 : 0)
      })
      if (cnt === 0) {
        Vue.delete(state.facets, payload.parent)
        delete state.facets[payload.parent]
      }
    }
  },

  [MUTATION.ADD_PARENT]: (state, payload) => {
    Vue.set(state.facets, payload.id, cloneDeep(payload))
    Object.keys(payload.children).map(key => {
      Vue.set(state.facets, key, cloneDeep(payload.children[key]))
    })
  },

  [MUTATION.REMOVE_PARENT]: (state, payload) => {
    Vue.delete(state.facets, payload.id)
    delete state.facets[payload.id]
    Object.keys(payload.children).map(key => {
      Vue.delete(state.facets, key)
      delete state.facets[key]
    })
  },

  [MUTATION.SET_QUERY]: (state, payload) => {
    Vue.set(state, 'query', payload)
  },

  [MUTATION.RESET_QUERY]: (state) => {
    const fresh = createFreshState()
    Vue.set(state, 'query', fresh.query)
  },

  [MUTATION.RESET]: (state) => {
    const fresh = createFreshState()
    Vue.set(state, 'facets', fresh.facets)
    Vue.set(state, 'query', fresh.query)
  },

  [MUTATION.SET_COMPANY_TRENDS_ONLY]: (state, payload) => {
    if (payload === true) {
      state.trendoneTrendsOnly.microtrends = false
    }
    state.companyTrendsOnly.microtrends = payload
  },

  [MUTATION.SET_TRENDONE_TRENDS_ONLY]: (state, payload) => {
    if (payload === true) {
      state.companyTrendsOnly.microtrends = false
    }
    state.trendoneTrendsOnly.microtrends = payload
  },

  [MUTATION.SET_TRENDS_WITH_USE_CASES_ONLY]: (state, payload) => {
    state.trendsWithUseCasesOnly = payload
  },

  [MUTATION.SET_SIDEBAR]: (state, payload) => {
    state.sidebar = payload
  }
}
