export const TRENDRADAR = 'TRENDRADAR'
export const TRENDRADAR_SHARING_OPTIONS = 'TRENDRADAR_SHARING_OPTIONS'
export const TRENDRADAR_PUBLIC_EVALUATION = 'TRENDRADAR_PUBLIC_EVALUATION'
export const UNIVERSE_ADMIN = 'UNIVERSE_ADMIN'
export const INSIDER = 'INSIDER'
export const INSIGHTS = 'INSIGHTS'
export const WORKSPACE = 'WORKSPACE'
export const SEARCH_AGENT = 'SEARCH_AGENT'
export const INNOVATION_FIELD = 'INNOVATION_FIELD'
export const PUBLIC_INNOVATION_FIELDS = 'PUBLIC_INNOVATION_FIELDS'
export const COMPANY_MICRO_TREND = 'COMPANY_MICRO_TREND'
export const PINS = 'PINS'
export const COMPANY_MACRO_MEGA_TREND = 'COMPANY_MACRO_MEGA_TREND'
export const INNOVATION_PROJECT = 'INNOVATION_PROJECT'
export const PUBLIC_INNOVATION_PROJECTS = 'PUBLIC_INNOVATION_PROJECTS'
export const UNLOCKED_MICROTREND = 'UNLOCKED_MICROTREND'
export const AI_BETA = 'AI_BETA'

export const FEATURE_SET = {
  TRENDEXPLORER: [
    WORKSPACE,
    INSIGHTS,
    SEARCH_AGENT,
    INSIDER
  ]
}
