import actions from '@/store/trendradar/participate/actions'
import getters from '@/store/trendradar/participate/getters'
import mutations from '@/store/trendradar/participate/mutations'
import { state } from '@/store/trendradar/participate/state'

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
