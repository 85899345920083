import { BulkSelectionState, SupportedTypes } from '@/store/bulk-selection/state'

export const GET_BULK_SELECTION = 'GET_BULK_SELECTION'
export const GET_SELECTED_TRENDS = 'GET_SELECTED_TRENDS'

export type GetBulkSelection = (type: SupportedTypes) => boolean
export type GetSelectedTrends = (type: SupportedTypes) => Array<string>

export default {
  [GET_BULK_SELECTION]: (state: BulkSelectionState): GetBulkSelection => (type: SupportedTypes) => state[type].enabled,
  [GET_SELECTED_TRENDS]: (state: BulkSelectionState): GetSelectedTrends => (type: SupportedTypes) => state[type].trends
}
