
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { HIDE_EXPORT_DIALOG } from '@/store/exports/mutation-types'
import { PDF, PPTX, XLSX } from '@/model/export/formats'

// @ts-ignore
import exportGQL from '@/graphql/app/export/mutation/export.gql'
// @ts-ignore
import exportWorkspaceGQL from '@/graphql/app/export/mutation/export-workspace.gql'
// @ts-ignore
import pollGQL from '@/graphql/app/export/query/poll.gql'

import ToIcon from '@/components/to-icon/to-icon.vue'
import { EXPORT_DIALOG, EXPORT_NODE_ID, EXPORT_TITLE, EXPORT_TYPE } from '@/store/exports/getter-types'
import { GET_EXPORT_TEMPLATES } from '@/store/auth/getter-types'
import { GET_SORT, GET_XLSX_EXPORT_URL } from '@/store/workspace/getter-types'
import { HideExportDialog } from '@/store/exports/types'
import { Export, ExportTemplate, ExportType, WorkspaceExportSort } from '@/graphql/app/types'
import { WORKSPACE } from '@/model/export/types'

const START = 'start'
const RUN = 'run'
const READY = 'ready'
const ERROR = 'error'

const initState = {
  format: null,
  state: START,
  START,
  RUN,
  READY,
  ERROR,
  exportNode: null,
  result: null
}

const exportStore = namespace('exports')
const authStore = namespace('auth')
const workspaceStore = namespace('workspace')

@Component({
  apollo: {
    result: {
      fetchPolicy: 'network-only',
      query: pollGQL,
      variables () {
        return {
          id: this.state.exportNode
        }
      },
      pollInterval: 2500,
      update (data) {
        switch (data.node.status) {
        case ERROR:
          this.state.state = ERROR
          break
        case READY:
          this.state.state = READY
          return data.node
        }
        return null
      },
      skip () {
        return this.state.exportNode === null || this.state.state !== RUN
      }
    }
  },
  components: {
    ToIcon
  }
})
export default class ExportDialog extends Vue {
  @exportStore.Getter(EXPORT_DIALOG) dialog!: boolean
  @exportStore.Getter(EXPORT_NODE_ID) id!: string | null
  @exportStore.Getter(EXPORT_TITLE) title!: string | null
  @exportStore.Getter(EXPORT_TYPE) type!: string | null

  @exportStore.Mutation(HIDE_EXPORT_DIALOG) hide!: HideExportDialog

  @authStore.Getter(GET_EXPORT_TEMPLATES) allExportTemplates!: Array<ExportTemplate>

  @workspaceStore.Getter(GET_SORT) workspaceSort!: WorkspaceExportSort
  // eslint-disable-next-line no-undef
  @workspaceStore.Getter(GET_XLSX_EXPORT_URL) workspaceXlsxExportUrl!: string

  state = {
    ...initState
  }

  result: Export | null = null

  exportTemplate: string | null = null

  @Watch('type', { immediate: true })
  onTypeChange () {
    this.setDefaultTemplate()
  }

  get show () {
    return this.dialog
  }

  set show (value) {
    if (!value) {
      this.reset()
    }
  }

  get mutation () {
    if (this.type === WORKSPACE) {
      return exportWorkspaceGQL
    }

    return exportGQL
  }

  get exportInput () {
    if (this.type === WORKSPACE) {
      return {
        id: this.id,
        format: this.state.format,
        template: this.exportTemplate,
        sort: this.workspaceSort
      }
    }

    return {
      id: this.id,
      format: this.state.format,
      template: this.exportTemplate
    }
  }

  get exportTemplates () {
    return this.allExportTemplates
      .filter(value => value.exportTypes?.includes(this.type as ExportType))
      .map(value => ({
        text: value.title,
        value: value.id
      }))
  }

  async onExport () {
    if (this.state.format === XLSX) {
      this.result = {
        id: this.id || '',
        url: this.workspaceXlsxExportUrl
      }
      this.state.state = this.state.READY

      return
    }

    try {
      this.state.state = this.state.RUN

      const result = await this.$apollo.mutate({
        mutation: this.mutation,
        variables: {
          input: this.exportInput
        }
      })
      // @ts-ignore
      this.$track(this.type, 'export', this.state.format)
      this.state.exportNode = result.data.export.id
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('%cError on export mutation: ', 'color:red', error)
      this.state.state = this.state.ERROR
    }
  }

  reset () {
    this.hide()
    this.state = {
      ...initState
    }
  }

  get availableFormats (): string[] {
    const defaultFormats = [PDF, PPTX]

    if (this.type === WORKSPACE) {
      return [...defaultFormats, XLSX]
    }

    return defaultFormats
  }

  private setDefaultTemplate () {
    if (this.exportTemplates.length > 0) {
      this.exportTemplate = this.exportTemplates[0].value
    }
  }
}
