import Vue from 'vue'
import {
  BULK_SELECTION_MACROTRENDS,
  BULK_SELECTION_MICROTRENDS,
  BulkSelectionState,
  defaultState,
  SupportedTypes
} from '@/store/bulk-selection/state'

export const SET_BULK_SELECTION = 'SET_BULK_SELECTION'
export const SET_SELECTED_TRENDS = 'SET_SELECTED_TRENDS'
export const TOGGLE_BULK_SELECTION = 'TOGGLE_BULK_SELECTION'

export const RESET_BULK_SELECTION = 'RESET_BULK_SELECTION'

interface SetBulkSelectionPayload {
  type: SupportedTypes
  value: boolean
}

interface ToggleBulkSelectionPayload {
  type: SupportedTypes
}

export type SetBulkSelection = (payload: SetBulkSelectionPayload) => void
export type ResetBulkSelection = () => void
export type ToggleBulkSelection = (payload: ToggleBulkSelectionPayload) => void
interface SelectionPayload {
  type: SupportedTypes,
  trends: Array<string>
}

export type SetSelectedTrends = (payload: SelectionPayload) => void

function setBulkSelection (state: BulkSelectionState, payload: SetBulkSelectionPayload) {
  if (!payload.value) {
    Vue.set(state[payload.type], 'trends', [])
  }
  Vue.set(state[payload.type], 'enabled', payload.value)
}

function toggleBulkSelection (state: BulkSelectionState, payload: ToggleBulkSelectionPayload) {
  setBulkSelection(state, { ...payload, value: !state[payload.type].enabled })
}

function setSelectedTrends (state: BulkSelectionState, payload: SelectionPayload) {
  Vue.set(state[payload.type], 'trends', payload.trends)
}

function resetBulkSelection (state: BulkSelectionState) {
  Vue.set(state, BULK_SELECTION_MICROTRENDS, defaultState())
  Vue.set(state, BULK_SELECTION_MACROTRENDS, defaultState())
}

export default {
  [SET_BULK_SELECTION]: setBulkSelection,
  [TOGGLE_BULK_SELECTION]: toggleBulkSelection,
  [SET_SELECTED_TRENDS]: setSelectedTrends,
  [RESET_BULK_SELECTION]: resetBulkSelection
}
