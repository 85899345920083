<template>
  <v-app-bar
    v-if="mobile"
    :height="50"
    class="align-content-center"
    clipped-left
    color="white"
    flat
  >
    <v-btn
      icon
      @click="internal = !internal"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <div class="logo-container">
      <router-link :to="{ name: 'dashboard' }">
        <div class="logo"></div>
      </router-link>
    </div>
  </v-app-bar>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Navigation extends Vue {
  @Prop({ type: Boolean, default: () => false }) value
  @Prop({ type: Boolean, default: () => false }) mobile

  get internal () {
    return this.value
  }

  set internal (value) {
    this.$emit('input', value)
  }
}
</script>
