// TODO: replace by mixin
import * as innovationProjectsService from '@/service/innovation-projects'
import * as ACTION from '@/store/innovation-project/action-types'

export default {
  [ACTION.UPDATE_PUBLIC_INNOVATION_PROJECTS_STATE]: async (state, payload) => {
    return await innovationProjectsService.updatePublicInnovationProjectsState(payload)
  },

  [ACTION.GET_PUBLIC_INNOVATION_PROJECTS_URL]: async (state) => {
    return await innovationProjectsService.getPublicInnovationProjectsUrl()
  }
}
