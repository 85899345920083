<template>
  <v-btn
    @click="onClick"
    :color="active ? 'primary' : ''"
    small
    icon
  >
    <v-icon
      class="text-primary"
      small
    >
      <template v-if="isDesc">
        {{ iconAsc }}
      </template>
      <template v-else>
        {{ iconDesc }}
      </template>
    </v-icon>
  </v-btn>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ASC, DESC } from '@/model/workspace/sort'

@Component({
  name: 'SortButton'
})
export default class SortButton extends Vue {
  @Prop({
    type: String,
    required: true
  }) iconDesc

  @Prop({
    type: String,
    required: true
  }) iconAsc

  @Prop({
    type: Boolean,
    required: false
  }) active

  @Prop({
    type: String,
    validator: value => value === DESC || value === ASC
  }) direction

  get isDesc () {
    return this.direction === DESC
  }

  get classes () {
    return { 'text-primary': this.active }
  }

  onClick () {
    this.$emit('click')
  }
}
</script>

<style scoped>

</style>
