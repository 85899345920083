import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from './auth'
import bulkSelection from './bulk-selection'
import exports from './exports'
import flag from './flag'
import gui from './gui'
import innovationField from './innovation-field'
import innovationProject from './innovation-project'
import microtrend from './microtrend'
import search from './search'
import searchAgent from './search-agent'
import trendradar from './trendradar'
import workspace from './workspace'
// import testSearch from './test-search'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    bulkSelection,
    exports,
    flag,
    gui,
    innovationField,
    innovationProject,
    microtrend,
    search,
    searchAgent,
    trendradar,
    workspace
    //, testSearch
  },
  plugins: [
    createPersistedState({
      key: 'trendmanager',
      paths: ['auth', 'flag']
    })
  ],
  // eslint-disable-next-line no-undef
  strict: process.env.NODE_ENV !== 'production'
})
