<template>
  <v-dialog
    v-model="show"
    max-width="650"
    scrollable
  >
    <v-card
      v-if="workspaces"
    >
      <v-card-title>
        <h3>
          {{ $t(`components.workspace.addSearchAgent.title`) }}
        </h3>
        <v-spacer/>
        <v-btn
          dark
          icon
          text
          @click="show = false"
        >
          <to-icon>close</to-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <h4>
          {{ $t('components.workspace.addSearchAgent.step1.title') }}
        </h4>
        <v-text-field
          v-model="$v.value.searchAgentTitle.$model"
          :error-messages="titleErrors"
          :label="$t(`components.workspace.addSearchAgent.step1.label`)"
          filled
        />
      </v-card-text>

      <v-card-text>
        <h4>{{ $t('components.workspace.addSearchAgent.step2.title') }}</h4>
        <v-list
          class="overflow-y-auto pt-1"
          height="200"
        >
          <v-list-item
            v-for="ws in workspaces.nodes"
            :key="ws.id"
          >
            <v-list-item-action>
              <v-checkbox
                v-model="$v.value.workspaces.selected.$model"
                :error="workspacesErrors.length > 0"
                :value="ws.id"
              />
            </v-list-item-action>
            <v-list-item-title>
              {{ ws.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-text>
        <h4>
          {{ $t('components.workspace.addSearchAgent.step3.title') }}
        </h4>
        <v-text-field
          v-model="$v.value.workspaces.newTitle.$model"
          :error-messages="workspacesErrors"
          :label="$t(`components.workspace.addSearchAgent.step3.label`)"
          filled
        >
        </v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn
          :disabled="disabled"
          :loading="pending"
          color="primary"
          @click.native="onSubmit()"
        >
          {{ $t('components.workspace.addSearchAgent.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-container
      v-else
    >
      <loading-circle
        class="pa-5"
      />
    </v-container>
  </v-dialog>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { required } from 'vuelidate/lib/validators'

import { HIDE_ADD_GENERIC, SHOW_SNACKBAR } from '@/store/gui/mutation-types'
import { GET_QUERY, GET_TAGS } from '@/store/search/getter-types'
import { GET_LOCALE } from '@/store/auth/getter-types'

import queryWorkspaces from '@/graphql/app/workspace/query/workspaces.gql'
import createSearchAgent from '@/graphql/app/search-agent/mutation/create.gql'

import LoadingCircle from '@/components/loading-circle/loading-circle'
import ToIcon from '@/components/to-icon/to-icon'
import { GET_CREATE_SEARCH_AGENT } from '@/store/gui/getter-types'

export const initSearchAgent = () => {
  return {
    searchAgentTitle: '',
    workspaces: {
      selected: [],
      newTitle: null
    }
  }
}

const authStore = namespace('auth')
const guiStore = namespace('gui')
const searchStore = namespace('search')

@Component({
  apollo: {
    workspaces: {
      fetchPolicy: 'network-only',
      query () {
        return queryWorkspaces
      },
      update (data) {
        return data.workspaces
      },
      skip () {
        return !this.showModal
      },
      error (error) {
        console.log('%cError on Query: ', 'color:red', error) // eslint-disable-line no-console
      }
    }
  },
  components: {
    LoadingCircle,
    ToIcon
  },
  validations: {
    value: {
      searchAgentTitle: {
        required
      },
      workspaces: {
        atLeastOneChosen (value) {
          return value.selected.length > 0 || (value.newTitle !== null && value.newTitle.length > 0)
        },
        selected: {},
        newTitle: {}
      }
    }
  }
})
export default class SearchAgentAdd extends Vue {
  workspaces = null

  pending = false

  value = initSearchAgent()

  @authStore.Getter(GET_LOCALE) locale

  @guiStore.Getter(GET_CREATE_SEARCH_AGENT) showModal

  @guiStore.Mutation(HIDE_ADD_GENERIC) hideCreate

  @guiStore.Mutation(SHOW_SNACKBAR) showSnackbar

  @searchStore.Getter(GET_TAGS) tags

  @searchStore.Getter(GET_QUERY) query

  get disabled () {
    return this.$v.$invalid || this.pending
  }

  get titleErrors () {
    const errors = []
    if (!this.$v.value.searchAgentTitle.$dirty) {
      return errors
    }
    !this.$v.value.searchAgentTitle.required && errors.push(this.$t('components.workspace.addSearchAgent.step1.error'))

    return errors
  }

  get workspacesErrors () {
    const errors = []
    if (!this.$v.value.workspaces.$anyDirty) {
      return errors
    }
    !this.$v.value.workspaces.atLeastOneChosen && errors.push(this.$t('components.workspace.addSearchAgent.step3.error'))

    return errors
  }

  get show () {
    return this.showModal && this.workspaces !== null
  }

  set show (_) {
    this.hide()
  }

  hide () {
    this.workspaces = null
    this.hideCreate()
    this.pending = false
    this.value = initSearchAgent()
    this.$v.$reset()
  }

  async onSubmit () {
    try {
      this.pending = true
      const data = await this.$apollo.mutate({
        mutation: createSearchAgent,
        variables: {
          input: {
            title: this.value.searchAgentTitle,
            workspaces: this.value.workspaces.selected,
            newWorkspaceTitle: this.value.workspaces.newTitle,
            search: {
              query: this.query,
              filter: this.tags.length > 0 ? this.retrieveFilters(this.tags) : null
            }
          }
        }
      })
      this.handleSuccess(data)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('%cError on add search agent:', 'color: red;', error)
    } finally {
      this.pending = false
    }
  }

  handleSuccess ({ data }) {
    this.$track('SearchAgent', 'create')
    if (Object.keys(data.createSearchAgent.workspaces).length > this.value.workspaces.selected.length) {
      this.$track('Workspace', 'create')
    }
    this.showSnackbar({
      mode: 'feedback',
      messages: [
        this.$t(
          'components.workspace.addSearchAgent.success',
          {
            title: this.value.searchAgentTitle
          }
        )
      ],
      targets: data.createSearchAgent.workspaces.map(elem => ({
        to: {
          name: 'workspace-detail',
          params: { workspaceId: elem.id }
        },
        title: elem.title
      }))
    })
    this.hide()
  }

  retrieveFilters (tags) {
    const filterMap = {}
    tags.map(tag => {
      filterMap[tag.filter] = filterMap[tag.filter] ? [...filterMap[tag.filter], tag.id] : [tag.id]
    })

    return filterMap
  }
}
</script>
