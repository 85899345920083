import { MegatrendInterface } from '@/graphql/app/types'
import Macrotrend from '@/model/trends/macrotrend'

export default class Megatrend {
  public readonly id: string
  public readonly title: string
  public readonly color: string
  public open: boolean = true
  public visible: boolean = true
  public macrotrends: Record<string, Macrotrend> = {}

  constructor (id: string, title: string, color: string) {
    this.id = id
    this.title = title
    this.color = color
  }

  public static fromMegatrendInterface (megatrend: MegatrendInterface) {
    return new Megatrend(megatrend.id, Megatrend.firstTranslationTitle(megatrend), megatrend.color || 'grey')
  }

  public addMacrotrend (macrotrend: Macrotrend): void {
    this.macrotrends[macrotrend.id] = macrotrend
  }

  public hasMacrotrend (id: string): boolean {
    return id in this.macrotrends
  }

  public getMacrotrend (id: string): Macrotrend | null {
    return this.hasMacrotrend(id)
      ? this.macrotrends[id]
      : null
  }

  private static firstTranslationTitle (megatrend: MegatrendInterface): string {
    if (megatrend.translations && megatrend.translations[0]) {
      return megatrend.translations[0].title || ''
    }

    return ''
  }
}
