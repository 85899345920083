export const CHALLENGES_AND_OPPORTUNITIES = 'ChallengesAndOpportunities'
export const CHALLENGES_AND_OPPORTUNITIES_TRANSLATION = 'ChallengesAndOpportunitiesTranslation'
export const COMMENT = 'Comment'
export const COMPANY = 'Company'
export const COMPANY_MACROTREND = 'CompanyMacrotrend'
export const COMPANY_MACROTREND_TRANSLATION = 'CompanyMacrotrendTranslation'
export const COMPANY_MEGATREND = 'CompanyMegatrend'
export const COMPANY_MICROTREND = 'CompanyMicrotrend'
export const COUNTRY = 'Country'

export const DRIVERS = 'Drivers'
export const DRIVERS_TRANSLATION = 'DriversTranslation'

export const EVALUATION = 'Evaluation'

export const INDUSTRY = 'Industry'
export const INITIATOR = 'Initiator'
export const INNOVATION_FIELD = 'InnovationField'
export const INNOVATION_TYPE = 'InnovationType'
export const INNOVATION_PROJECT = 'InnovationProject'
export const INSIDER = 'Insider'
export const INSIGHT_AUTHOR = 'InsightAuthor'

export const MACROTREND = 'Macrotrend'
export const MACROTREND_EVALUATION = 'MacrotrendEvaluation'
export const MACROTREND_TRANSLATION = ''
export const MAINSTREAM_ADOPTION = 'MainstreamAdoption'
export const MAINSTREAM_ADOPTION_TRANSLATION = 'MainstreamAdoptionTranslation'
export const MANAGED_MACROTREND_EVALUATION = 'ManagedMacrotrendEvaluation'
export const MEGATREND = 'Megatrend'
export const MICROTREND = 'Microtrend'
export const MICROTREND_PIN = 'MicrotrendPin'

export const POST = 'Post'

export const REGION = 'Region'

export const SEARCH_AGENT = 'SearchAgent'

export const TRENDRADAR_DRAFT = 'TrendRadarDraft'
export const TRENDRADAR_PUBLISHED = 'PublishedTrendRadar'
export const TRENDRADAR_PARTICIPATION = 'TrendRadarParticipation'
export const TRENDONE_MACROTREND = 'TrendoneMacrotrend'
export const TRENDONE_MEGATREND = 'TrendoneMegatrend'
export const TRENDING_TOPIC = 'TrendingTopic'
export const TREND_SUMMARY = 'TrendSummary'
export const TREND_BRIEFING = 'TrendBriefing'
export const TRENDCALL = 'Trendcall'
export const TRENDRADAR_MACROTREND_EVALUATION = 'TrendRadarMacrotrendEvaluation'

export const USER = 'User'
export const PARTICIPANT = 'Participant'

export const PAGINATED_PAGE_INFO = 'PaginatedPageInfo'

export const WORKSPACE = 'Workspace'

export const COMPANY_TRENDS = [COMPANY_MACROTREND, COMPANY_MEGATREND]
export const INSIGHTS = [TRENDCALL, TRENDING_TOPIC]
export const MACROTRENDS = [COMPANY_MACROTREND, MACROTREND]
export const MACROTREND_EVALUATIONS = [MANAGED_MACROTREND_EVALUATION, MACROTREND_EVALUATION, TRENDRADAR_MACROTREND_EVALUATION]
export const MEGATRENDS = [COMPANY_MEGATREND, MEGATREND]
export const MICROTRENDS = [COMPANY_MICROTREND, MICROTREND]
export const TRENDONE_TRENDS = [TRENDONE_MACROTREND, TRENDONE_MEGATREND]
export const TRENDRADARS = [TRENDRADAR_DRAFT, TRENDRADAR_PUBLISHED]

export const TRENDS = MICROTRENDS.concat(MICROTRENDS, MACROTRENDS, MEGATRENDS)

export const UNIVERSE = 'Universe'
