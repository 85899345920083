export const linkSanitizer = (url) => {
  if (url.toLowerCase().indexOf('mailto') !== -1) {
    return url
  }

  if (!url.toLowerCase().startsWith('http')) {
    return `http://${url}`
  }
  return url
}
