import * as GETTER from './getter-types'

export default {
  [GETTER.GET_DETAILS_SHOW]: (state) => state.details.show,

  [GETTER.GET_DETAILS]: (state) => state.details,

  [GETTER.GET_MODE]: (state) => state.mode,

  [GETTER.GET_HIGHLIGHT]: (state) => state.highlight,

  [GETTER.GET_RELOAD_POSTS_CNT]: (state) => state.reloadPostsCnt,

  [GETTER.GET_RELOAD_POSTS_TYPE]: (state) => state.reloadPostsType,

  [GETTER.GET_VIEW]: (state) => state.view,

  [GETTER.GET_CHART_CONFIGURATION]: (state) => state.chartConfiguration,

  [GETTER.GET_CRITERIAS]: (state) => state.criteriaSet.criterias,

  [GETTER.GET_CRITERIA_SET]: (state) => state.criteriaSet,

  [GETTER.GET_FILTER]: (state) => state.filter
}
