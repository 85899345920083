import { appClient } from '@/plugins/apollo'
import setLastFeatureUpdateGQL from '@/graphql/app/user/mutation/set-last-feature-update.gql'
import updateProfileGQL from '@/graphql/app/user/mutation/profile.gql'

export const setLastFeatureUpdate = async () => {
  try {
    const me = await appClient.mutate({
      mutation: setLastFeatureUpdateGQL,
      fetchPolicy: 'no-cache'
    })
    return me.data.lastFeatureUpdate
  } catch (error) {
    console.log('%cError on service.auth.me: ', 'color:red', error) // eslint-disable-line no-console
    // todo: replace string by error object
    // eslint-disable-next-line no-throw-literal
    throw 'service.auth.me'
  }
}

export const disableFeatureUpdates = async () => {
  await appClient.mutate({
    mutation: updateProfileGQL,
    variables: {
      input: {
        featureUpdatesDisabled: true
      }
    },
    fetchPolicy: 'no-cache'
  })
}
