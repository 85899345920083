import * as Mutation from '@/store/innovation-field/mutation-types'
import { empty } from './state'
import Vue from 'vue'

export default {
  [Mutation.SET_CREATED_NEW]: (state, payload) => {
    Vue.set(state, 'createdNew', payload)
  },

  [Mutation.HIDE_ALL_DIALOGS]: (state) => {
    Object.assign(state, empty())
  },

  [Mutation.SET_INNOVATION_FIELD]: (state, payload) => {
    const updatedData = state.input
    Vue.set(updatedData, payload.name, payload.value)
    Vue.set(state, 'input', updatedData)
  },

  [Mutation.SHOW_DELETE_DIALOG]: (state, payload) => {
    Vue.set(state, 'delete', true)
    Vue.set(state, 'id', payload.id)
    Vue.set(state.input, 'title', payload.title)
  },

  [Mutation.SHOW_EDIT_DIALOG]: (state, payload) => {
    Vue.set(state, 'edit', true)

    if (payload) {
      Vue.set(state, 'id', payload.id)
      Vue.set(state.input, 'title', payload.title)
      Vue.set(state.input, 'description', payload.description)
      Vue.set(state.input, 'image', payload.image)
      Vue.set(state.input, 'contactPersonId', payload.contactPerson ? payload.contactPerson.id : null)
      Vue.set(
        state.input,
        'macrotrends',
        payload.macrotrends && payload.macrotrends.length > 0 ? payload.macrotrends.map(trend => trend.id) : []
      )
      Vue.set(
        state.input,
        'trendRadars',
        payload.trendRadars.nodes && payload.trendRadars.nodes.length > 0 ? payload.trendRadars.nodes.map(node => node.id) : []
      )
    }
  }
}
