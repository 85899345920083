import * as Mutation from '@/store/exports/mutation-types'
import { initState } from '@/store/exports/state'

export default {
  [Mutation.SHOW_EXPORT_DIALOG]: (state, payload) => {
    Object.assign(state, initState(), payload, { dialog: true })
  },
  [Mutation.HIDE_EXPORT_DIALOG]: (state) => {
    Object.assign(state, initState())
  }
}
