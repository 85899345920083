import { appClient } from '@/plugins/apollo'

import updatePublicInnovationProjectsStateGQL
  from '@/graphql/app/innovation-project/mutation/update-public-innovation-projects-state.gql'
import publicInnovationProjectsUrlGQL from '@/graphql/app/innovation-project/query/public-innovation-projects-url.gql'

export const updatePublicInnovationProjectsState = async (payload) => {
  const response = await appClient.mutate({
    mutation: updatePublicInnovationProjectsStateGQL,
    variables: {
      input: { active: payload }
    }
  })
  return response.data.updatePublicInnovationProjectsState
}

export const getPublicInnovationProjectsUrl = async () => {
  const response = await appClient.query({
    query: publicInnovationProjectsUrlGQL,
    fetchPolicy: 'no-cache'
  })
  return response.data.publicInnovationProjectsUrl
}
