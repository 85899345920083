import { render, staticRenderFns } from "./use-cases.vue?vue&type=template&id=2eabe589&"
import script from "./use-cases.vue?vue&type=script&setup=true&lang=ts&"
export * from "./use-cases.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports