<template>
  <new-features-overlay
    v-model="show"
    @disable-updates="onDisableUpdates"
    @show-feature-updates="onShowFeatureUpdates"
  />
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import NewFeaturesOverlay from './new-features-dialog'
import { namespace } from 'vuex-class'
import { GET_FEATURE_UPDATES, GET_FEATURE_UPDATES_DISABLED } from '@/store/auth/getter-types'
import { GET_FEATURE_UPDATE_DIALOG } from '@/store/gui/getter-types'
import { CLOSE_FEATURE_UPDATE_DIALOG } from '@/store/gui/mutation-types'
import { DISABLE_FEATURE_UPDATES, SET_LAST_FEATURE_UPDATE } from '@/store/gui/action-types'

const auth = namespace('auth')
const gui = namespace('gui')

@Component({
  components: {
    NewFeaturesOverlay
  }
})
export default class NewFeatures extends Vue {
  @auth.Getter(GET_FEATURE_UPDATES_DISABLED) featureUpdatesDisabled
  @auth.Getter(GET_FEATURE_UPDATES) featureUpdates

  @gui.Getter(GET_FEATURE_UPDATE_DIALOG) showFeatureUpdatesDialog
  @gui.Mutation(CLOSE_FEATURE_UPDATE_DIALOG) closeFeatureUpdatesDialog
  @gui.Action(DISABLE_FEATURE_UPDATES) disableFeatureUpdates
  @gui.Action(SET_LAST_FEATURE_UPDATE) setLastFeatureUpdate

  get show () {
    return !this.featureUpdatesDisabled && this.featureUpdates > 0 && this.showFeatureUpdatesDialog
  }

  set show (_) {
    this.closeFeatureUpdatesDialog()
  }

  async onDisableUpdates () {
    await this.disableFeatureUpdates()
  }

  async onShowFeatureUpdates () {
    await this.setLastFeatureUpdate()
  }
}
</script>
