export const GET_MICROTREND = 'getMicrotrend'

export const IS_SNACKBAR = 'isSnackbar'
export const GET_SNACKBAR_MESSAGES = 'getSnackbarMessages'
export const GET_SNACKBAR_TARGETS = 'getSnackbarTargets'
export const GET_SNACKBAR_TIMEOUT = 'getSnackbarTimeout'
export const GET_SNACKBAR_MODE = 'getSnackbarMode'

export const GET_ADD_TRENDS_TO_WORKSPACE = 'getAddTrendsToWorkspace'
export const GET_ADD_TREND_TO_INNOVATION_FIELD = 'getAddTrendToInnovationfield'

export const GET_CREATE_SEARCH_AGENT = 'getCreateSearchAgent'

export const SHOW_CREATE_TRENDRADAR_RELATED = 'showCreateTrendradarRelated'
export const SELECTION_CREATE_TRENDRADAR_RELATED = 'selectionCreateTrendradarRelated'
export const CONTEXT_CREATE_TRENDRADAR_RELATED = 'contextCreateTrendradarRelated'
export const SHOW_INNOVATION_PROJECT_DIALOG = 'showInnovationProjectDialog'

export const GET_PIN = 'GET_PIN'
export const GET_IMAGE = 'getImage'

export const GET_BACK_URL = 'GET_BACK_URL'

export const GET_VIEW_MODE = 'GET_VIEW_MODE'
export const GET_VIEW_CONFIG = 'GET_VIEW_CONFIG'
export const GET_PAGINATION = 'GET_PAGINATION'
export const GET_PAGE = 'GET_PAGE'
export const GET_SORT = 'GET_SORT'

export const GET_CREATE_FEEDBACK = 'GET_CREATE_FEEDBACK'
export const GET_FEATURE_UPDATE_DIALOG = 'GET_FEATURE_UPDATE_DIALOG'

export const GET_SHOW_UPDATE_DIALOG = 'GET_SHOW_UPDATE_DIALOG'
export const GET_WORKSPACE_DEPRECATED_DIALOG = 'GET_WORKSPACE_DEPRECATED_DIALOG'
