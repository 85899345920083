export const IS_TRENDEXPLORER_COMPANY = 'isTrendExplorerCompany'
export const IS_AUTHENTICATED = 'isAuthenticated'
export const IS_PENDING = 'isPending'
export const GET_USERNAME = 'getUsername'
export const GET_FULLNAME = 'getFullname'
export const GET_LAST_LOGIN = 'getLastLogin'
export const GET_COMPANY = 'getCompany'
export const GET_COMPANY_LOCALES = 'getCompanyLocales'
export const GET_COMPANY_FEATURES = 'getCompanyFeatures'
export const GET_AVATAR = 'getAvatar'
export const GET_USER_ID = 'getUserId'
export const RULES = 'rules'
export const GET_LOCALE = 'getLocale'
export const ROLES = 'roles'
export const IS_EWE_USER = 'isEweUser'
export const IS_EWE_COMPANY = 'companyIsEWE'
export const GET_TOGGLE_LOCALE = 'getToggleLocale'
export const GET_COMPANY_COLOR = 'getCompanyColor'
export const IS_FEATURE_ENABLED = 'IS_FEATURE_ENABLED'
export const TOKEN_IS_VALID = 'TOKEN_IS_VALID'
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED'
export const GET_PUBLIC_INNOVATION_FIELDS_STATE = 'GET_PUBLIC_INNOVATION_FIELDS_STATE'
export const GET_PUBLIC_INNOVATION_PROJECTS_STATE = 'GET_PUBLIC_INNOVATION_PROJECTS_STATE'
export const GET_ADMIN_ACTS_AS_USER = 'GET_ADMIN_ACTS_AS_USER'
export const SHOW_INTRO_VIDEO = 'SHOW_INTRO_VIDEO'

export const IS_TEST_COMPANY = 'IS_TEST_COMPANY'
export const GET_EXPORT_TEMPLATES = 'GET_EXPORT_TEMPLATES'
export const GET_INSIGHTS_UPDATES = 'GET_INSIGHTS_UPDATES'
export const GET_FEATURE_UPDATES = 'GET_FEATURE_UPDATES'
export const GET_FEATURE_UPDATES_DISABLED = 'GET_FEATURE_UPDATES_DISABLED'
export const IS_AUTOMATIC_UNIVERSE_UPDATE = 'IS_AUTOMATIC_UNIVERSE_UPDATE'
export const GET_TRACKING_ID = 'GET_TRACKING_ID'

export const GET_2FA = 'GET_2FA'
