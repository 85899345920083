<template>
  <v-container
    v-if="microtrend"
    :key="microtrend.id"
    class="microtrend-detail-view"
  >
    <v-row
      class="microtrend-details"
    >
      <v-col
        class="microtrend-content"
        cols="12"
        sm="8"
      >
        <v-card
          flat
          text
          tile
        >
          <v-img
            :height="400"
            :src="microtrend.images[0]"
          >
            <slot name="actions">
            </slot>
            <company-microtrend-marker
              :obj="microtrend"
              :title="$t('components.microtrend.overlay.marker')"
            />
            <div
              v-if="!translated(microtrend.translations)"
              class="no-translation"
            >
              {{ $t('components.microtrend.overlay.noTranslation') }}
            </div>
          </v-img>

          <v-card-title
            class="h5 pl-6 pt-8"
          >
            {{ retrieveTranslation(microtrend.translations).title }}
          </v-card-title>

          <v-card-text
            class="pb-1 pl-6 pr-8"
            v-html="retrieveTranslation(microtrend.translations).description"
          />

          <v-card-text
            v-if="isCompanyMicrotrend"
            class="px-2 pt-1"
          >
            <microtrend-comment
              :obj="microtrend"
            />
          </v-card-text>

          <use-cases-container
            :microtrend-id="microtrend.id"
            :locale="locale"
            v-if="renderUseCasesContainer"
          />

          <v-card-actions class="pr-2 pt-8">
            <v-spacer />
            <v-btn
              :href="microtrend.url"
              class="micotrend-website"
              target="_blank"
              text
            >
              {{ $t('components.microtrend.overlay.website') }}
              <to-icon right>link</to-icon>
            </v-btn>
          </v-card-actions>

          <div
            v-if="video"
            class="microtrend-video"
          >
            <video-player
              :src="video"
            />
          </div>
        </v-card>
      </v-col>

      <v-col
        class="microtrend-meta"
        cols="12"
        sm="4"
      >
        <v-card
          flat
          tile
        >
          <v-card-actions
            class="overlay-actions"
          >
            <v-spacer />
            <context-menu
              v-if="isCompanyMicrotrend"
              :obj="microtrend"
            />
            <share-action-button
              v-if="shareable"
              :publicUrl="microtrend.publicUrl"
            />
            <v-btn
              color="primary"
              icon
              @click="onExport"
              title="Micro-Trend exportieren"
            >
              <to-icon>export</to-icon>
            </v-btn>
            <add-button
              :microtrendIds="[microtrend.id]"
              flat
              left
            />
            <v-btn
              color="primary"
              icon
              @click="onExit"
            >
              <to-icon>close</to-icon>
            </v-btn>
          </v-card-actions>

          <v-divider />

          <template
            v-if="isCompanyMicrotrend"
          >
            <person
              :obj="author"
              :title="$t('components.microtrend.overlay.author')"
            />
            <v-divider />
          </template>

          <v-list
            subheader
          >
            <v-list-item
              v-if="microtrend.initiators.length > 0"
            >
              <v-list-item-icon>
                <to-icon
                  color="grey"
                >
                  user
                </to-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-for="(initiator, idx) in microtrend.initiators"
                  :key="idx"
                >
                  {{ initiator.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <to-icon
                  color="grey"
                >
                  location
                </to-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-for="(country, idx) in countries"
                  :key="idx"
                >
                  {{ country }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <to-icon
                  color="grey"
                >
                  clock
                </to-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ date(microtrend.identifiedAt) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div class="megatrends-tags trendtags">
            <div
              v-if="microtrend.megatrends.edges.length > 0"
              class="megatrends"
            >
              <p>{{ $t('components.microtrend.overlay.megatrends') }}</p>
              <p>
                <template v-for="megatrend in microtrend.megatrends.edges">
                  <trend-tag
                    v-if="megatrend.node"
                    :key="megatrend.node.id"
                    :node="megatrend.node"
                    :to="{name: 'megatrend-detail', params: { megatrendId: megatrend.node.id }}"
                  />
                </template>
              </p>
            </div>

            <macrotrends-wrapper
              v-model="$v.editable.macrotrendIds.$model"
              :microtrend="microtrend"
              :pending="pending"
              @submit="onMacrotrendsSubmit"
            />

            <industries-wrapper
              v-model="$v.editable.industryIds.$model"
              :microtrend="microtrend"
              :pending="pending"
              @submit="onIndustriesSubmit"
            />

            <innovation-types-wrapper
              v-model="$v.editable.innovationTypeIds.$model"
              :microtrend="microtrend"
              :pending="pending"
              @submit="onInnovationTypeSubmit"
            />

            <sdgs-view
              :microtrend="microtrend"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <div class="microtrend-slider-container">
      <h2>
        {{ $t('components.microtrend.overlay.related') }}
      </h2>
      <card-carousel
        :breakpoints="[[0, 1], [516, 2], [768, 3], [1024, 4], [1264, 4]]"
        :loaded="microtrend.relatedMicrotrends.nodes.length"
        :total="microtrend.relatedMicrotrends.nodes.length"
      >
        <slide
          v-for="(content, idx) in microtrend.relatedMicrotrends.nodes"
          :key="content.id"
          :index="idx"
        >
          <microtrend-card
            :content="content"
          />
        </slide>
      </card-carousel>
    </div>
    <v-dialog
      v-model="confirmation"
      :maxWidth="440"
      persistent
    >
      <v-card
        color="white"
        flat
        tile
      >
        <v-card-title>
          <h2>{{ $t('components.microtrend.overlay.confirmation.title') }}</h2>
        </v-card-title>
        <v-card-actions>
          <v-btn
            :disabled="pending"
            color="primary"
            text
            @click="confirmation = false"
          >
            {{ $t('components.microtrend.overlay.confirmation.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="pending"
            color="primary"
            text
            @click="onExit"
          >
            {{ $t('components.microtrend.overlay.confirmation.no') }}
          </v-btn>
          <v-btn
            :disabled="pending"
            :loading="pending"
            color="primary"
            depressed
            @click="onSave"
          >
            {{ $t('components.microtrend.overlay.confirmation.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { COMPANY_MICROTREND, MICROTRENDS } from '@/model/types'
import { MICROTREND as EXPORT_TYPE } from '@/model/export/types'
import { SHOW_EXPORT_DIALOG } from '@/store/exports/mutation-types'

import { DateMixin, FeatureCheckMixin, TranslationMixin } from '@/mixins'

import updateGQL from '@/graphql/app/company-microtrend/mutation/onsite-update.gql'

import AddButton from '@/components/microtrend/add-button/add-button'
import CardCarousel from '@/components/layout/card-carousel'
import CompanyMicrotrendMarker from '@/components/microtrend/card/company-microtrend-marker'
import ContextMenu from '@/components/company-microtrend/context-menu/context-menu'
import MacrotrendsWrapper from '@/components/microtrend/overlay/macrotrends'
import MicrotrendCard from '@/components/microtrend/card'
import MicrotrendComment from '@/components/microtrend/comment/comment'
import InnovationTypesWrapper from '@/components/microtrend/overlay/innovation-types'
import IndustriesWrapper from '@/components/microtrend/overlay/industries'
import Person from '@/components/person/person'
import SdgsView from '@/components/microtrend/overlay/sdgs/view.vue'
import ShareActionButton from '@/components/microtrend/overlay/share-action-button'
import { Slide } from 'hooper'
import ToIcon from '@/components/to-icon/to-icon'
import TrendTag from '@/components/trend-tag'
import VideoPlayer from '@/components/video-player/video-player.vue'
import UseCasesContainer from '@/components/microtrend/overlay/use-cases-container.vue'

const exports = namespace('exports')

@Component({
  components: {
    AddButton,
    CardCarousel,
    CompanyMicrotrendMarker,
    ContextMenu,
    MacrotrendsWrapper,
    MicrotrendCard,
    MicrotrendComment,
    InnovationTypesWrapper,
    IndustriesWrapper,
    UseCasesContainer,
    Person,
    SdgsView,
    ShareActionButton,
    Slide,
    ToIcon,
    TrendTag,
    VideoPlayer
  },
  validations: {
    editable: {
      industryIds: {},
      macrotrendIds: {},
      innovationTypeIds: {}
    }
  }
})
export default class MicrotrendOverlayContent extends Mixins(DateMixin, TranslationMixin, FeatureCheckMixin) {
  @Prop({
    type: Object,
    validator: (obj) => MICROTRENDS.includes(obj.__typename)
  }) microtrend

  @Prop({
    type: String,
    required: true
  }) locale

  @exports.Mutation(SHOW_EXPORT_DIALOG) showExportDialog

  pending = false
  confirmation = false

  editable = {
    macrotrendIds: [],
    industryIds: [],
    innovationTypeIds: []
  }

  get author () {
    return this.isCompanyMicrotrend
      ? this.microtrend.author
      : null
  }

  get isCompanyMicrotrend () {
    return this.microtrend.__typename === COMPANY_MICROTREND
  }

  get countries () {
    return this.microtrend.countries.map(country => {
      if (country.translations && country.translations[0]) {
        return country.translations[0].title
      }
      return 'N/A'
    })
  }

  get country () {
    if (this.microtrend.country && this.microtrend.country.translations && this.microtrend.country.translations[0]) {
      return this.microtrend.country.translations[0].title
    }

    return 'N/A'
  }

  get shareable () {
    return this.microtrend && this.microtrend.publicUrl
  }

  get video () {
    return this.microtrend && this.microtrend.videos.length > 0
      ? this.microtrend.videos[0]
      : false
  }

  get hasChanged () {
    return this.$v.editable.$anyDirty
  }

  onExport () {
    const payload = {
      type: EXPORT_TYPE,
      id: this.microtrend.id,
      title: this.microtrend.translations[0].title
    }
    this.showExportDialog(payload)
  }

  async onInnovationTypeSubmit () {
    await this.submit({
      id: this.microtrend.id,
      innovationTypeIds: this.editable.innovationTypeIds
    })
  }

  async onIndustriesSubmit () {
    await this.submit({
      id: this.microtrend.id,
      industryIds: this.editable.industryIds
    })
  }

  async onMacrotrendsSubmit () {
    await this.submit({
      id: this.microtrend.id,
      macrotrendIds: this.editable.macrotrendIds
    })
  }

  async submit (input) {
    try {
      this.pending = true
      const response = await this.$apollo.mutate({
        mutation: updateGQL,
        variables: {
          locale: this.locale,
          input: input
        }
      })
      this.editable = this.extractIds(response.data.updateCompanyMicrotrend)
      this.$v.$reset()
    } catch (e) {
      console.log('%cError on update company microtrend)', 'color: red;', e) // eslint-disable-line no-console
    } finally {
      this.pending = false
    }
  }

  get renderUseCasesContainer () {
    return !this.isCompanyMicrotrend
  }

  onExit () {
    if (this.hasChanged) {
      this.confirmation = true
    } else {
      this.$emit('close:overlay')
    }
  }

  async onSave () {
    await this.submit({
      id: this.microtrend.id,
      macrotrendIds: this.editable.macrotrendIds,
      industryIds: this.editable.industryIds,
      innovationTypeId: this.editable.innovationTypeId
    })
    this.onExit()
  }

  @Watch('microtrend')
  onChange (value) {
    this.editable = this.extractIds(value)
  }

  mounted () {
    this.editable = this.extractIds(this.microtrend)
  }

  extractIds (microtrend) {
    return microtrend
      ? {
        macrotrendIds: microtrend.macrotrends.edges.map(m => m.node.id),
        industryIds: microtrend.industries.edges.map(i => i.node.id),
        innovationTypeIds: microtrend.innovationTypes.map(t => t.id)
      }
      : {
        macrotrendIds: [],
        industryIds: [],
        innovationTypeIds: []
      }
  }
}
</script>
