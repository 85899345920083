import { GALLERY } from '@/model/views/modes'
import * as GETTER from './getter-types'

export default {
  [GETTER.GET_MICROTREND]: state => state.microtrend,

  [GETTER.IS_SNACKBAR]: state => state.snackbar.show,
  [GETTER.GET_SNACKBAR_MESSAGES]: state => state.snackbar.messages,
  [GETTER.GET_SNACKBAR_TARGETS]: state => state.snackbar.targets,
  [GETTER.GET_SNACKBAR_TIMEOUT]: state => state.snackbar.timeout,
  [GETTER.GET_SNACKBAR_MODE]: state => state.snackbar.mode,

  [GETTER.GET_ADD_TRENDS_TO_WORKSPACE]: state => state.add.workspaces,

  [GETTER.GET_ADD_TREND_TO_INNOVATION_FIELD]: state => state.add.innovationField,

  [GETTER.GET_CREATE_SEARCH_AGENT]: state => state.create.searchAgent,

  [GETTER.SHOW_CREATE_TRENDRADAR_RELATED]: state => state.create.trendradarRelated.show,
  [GETTER.SELECTION_CREATE_TRENDRADAR_RELATED]: state => state.create.trendradarRelated.tab,
  [GETTER.CONTEXT_CREATE_TRENDRADAR_RELATED]: state => state.create.trendradarRelated.context,

  [GETTER.SHOW_INNOVATION_PROJECT_DIALOG]: state => state.proposeInnovationProject.show,

  [GETTER.GET_PIN]: state => state.pin,

  [GETTER.GET_IMAGE]: state => state.lightbox.image,

  [GETTER.GET_BACK_URL]: state => state.backUrl,

  [GETTER.GET_VIEW_MODE]: state => id => state.viewConfig.views[id] ? state.viewConfig.views[id] : GALLERY,

  [GETTER.GET_VIEW_CONFIG]: state => id => state.viewConfig.configs[id],

  [GETTER.GET_PAGINATION]: state => id => state.viewConfig.pagination[id] ? state.viewConfig.pagination[id] : {},

  [GETTER.GET_PAGE]: state => id => state.viewConfig.pagination[id] && state.viewConfig.pagination[id].page ? state.viewConfig.pagination[id].page : 1,

  [GETTER.GET_SORT]: state => id => {
    let field = null
    let direction = 'desc'

    if (state.viewConfig.pagination[id]) {
      if (Array.isArray(state.viewConfig.pagination[id].sortBy)) {
        field = state.viewConfig.pagination[id].sortBy[0].replace(/-([a-z])/g, (g) => g[1].toUpperCase())
      }

      if (Array.isArray(state.viewConfig.pagination[id].sortDesc)) {
        direction = state.viewConfig.pagination[id].sortDesc[0] ? 'desc' : 'asc'
      }
    }

    if (field !== null) {
      return {
        field: field,
        direction: direction
      }
    }

    return null
  },

  [GETTER.GET_CREATE_FEEDBACK]: state => state.create.feedback,

  [GETTER.GET_FEATURE_UPDATE_DIALOG]: state => state.featureUpdateDialog,

  [GETTER.GET_SHOW_UPDATE_DIALOG]: state => state.universeUpdateDialog,

  [GETTER.GET_WORKSPACE_DEPRECATED_DIALOG]: state => state.workspaceDeprecatedDialog
}
