<template>
    <component
      :is="current"
      :microtrend="microtrend"
      @change-mode="onChangeMode"
      @submit="onSubmit"
      v-model="internal"
      :pending="pending"
      :mode="mode"
    />
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import { MICROTRENDS } from '@/model/types'
import { LIST, EDIT } from '@/model/views/modes'

import Edit from '@/components/microtrend/overlay/macrotrends/edit'
import View from '@/components/microtrend/overlay/macrotrends/view'

@Component
export default class MacrotrendsWrapper extends Vue {
  @Prop({ type: Object, validator: obj => MICROTRENDS.includes(obj.__typename), required: true }) microtrend
  @Prop({ type: Boolean, default: () => false }) pending
  @Prop({ type: Array }) value

  mode = LIST

  get current () {
    return this.components[this.mode]
  }

  get internal () {
    return this.value
  }

  set internal (value) {
    this.$emit('input', value)
  }

  onChangeMode (value) {
    this.mode = value
  }

  onSubmit () {
    this.$emit('submit')
  }

  created () {
    this.mode = LIST
    this.components = {
      [LIST]: View,
      [EDIT]: Edit
    }
  }
}
</script>
