<template>
  <v-list-item
    class="person"
    :dense="dense"
  >
    <v-list-item-avatar
      :size="22"
    >
      <img
        :alt="name"
        :src="image"
      >
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-if="title">
        {{ title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ name }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { INSIGHT_AUTHOR, USER } from '@/model/types'

const VALID = [USER, INSIGHT_AUTHOR]

@Component
export default class Person extends Vue {
  @Prop({
    type: Object,
    validator: obj => VALID.includes(obj.__typename)
  }) obj

  @Prop({
    type: String,
    default: () => null
  }) title

  @Prop({
    type: Boolean,
    default: false
  }) dense

  get name () {
    return this.obj.__typename === USER
      ? `${this.obj.firstName} ${this.obj.lastName}`
      : this.obj.name
  }

  get image () {
    return this.obj.__typename === USER
      ? this.obj.avatar
      : this.obj.image
  }
}
</script>
