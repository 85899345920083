interface State {
  enabled: boolean
  trends: Array<string>
}

export const BULK_SELECTION_MICROTRENDS = 'microtrends'
export const BULK_SELECTION_MACROTRENDS = 'macrotrends'

export type SupportedTypes = typeof BULK_SELECTION_MICROTRENDS | typeof BULK_SELECTION_MACROTRENDS

export interface BulkSelectionState {
  [namespace: string]: State
}

export const defaultState = (): State => ({
  enabled: false,
  trends: []
})

export const init = (): BulkSelectionState => ({
  [BULK_SELECTION_MICROTRENDS]: defaultState(),
  [BULK_SELECTION_MACROTRENDS]: defaultState()
})

export const state = init()
