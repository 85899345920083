var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.editor !== null)?_c('v-card',{class:[
    'tiptap-editor',
    _vm.hasError ? 'has-error' : ''
  ],attrs:{"flat":""}},[_c('v-toolbar',{staticClass:"px-0",attrs:{"dense":"","flat":""}},[_c('v-btn-toggle',{staticClass:"px-0",attrs:{"value":_vm.textFormat(_vm.editor),"multiple":""}},[_c('v-btn',{attrs:{"disabled":!_vm.editor.can().chain().focus().toggleBold().run(),"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.editor.chain().focus().toggleBold().run()}}},[_c('v-icon',[_vm._v("mdi-format-bold")])],1),_c('v-btn',{attrs:{"disabled":!_vm.editor.can().chain().focus().toggleItalic().run(),"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.editor.chain().focus().toggleItalic().run()}}},[_c('v-icon',[_vm._v("mdi-format-italic")])],1),_c('v-btn',{attrs:{"disabled":!_vm.editor.can().chain().focus().toggleUnderline().run(),"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.editor.chain().focus().toggleUnderline().run()}}},[_c('v-icon',[_vm._v("mdi-format-underline")])],1)],1),_c('v-btn-toggle',{staticClass:"pl-3",attrs:{"value":_vm.listFormat(_vm.editor),"multiple":""}},[_c('v-btn',{attrs:{"disabled":!_vm.editor.can().chain().focus().toggleOrderedList().run(),"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.editor.chain().focus().toggleOrderedList().run()}}},[_c('v-icon',[_vm._v("mdi-format-list-numbered")])],1),_c('v-btn',{attrs:{"disabled":!_vm.editor.can().chain().focus().toggleBulletList().run(),"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.editor.chain().focus().toggleBulletList().run()}}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)],1),_c('v-btn-toggle',{staticClass:"pl-3",attrs:{"value":_vm.misc(_vm.editor),"multiple":""}},[_c('v-btn',{attrs:{"disabled":!_vm.editor.can().chain().focus().toggleLink().run(),"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.setLink(_vm.editor)}}},[_c('v-icon',[_vm._v("mdi-link-variant")])],1)],1)],1),_c('div',{class:[
      'v-input v-input--has-state theme--light v-text-field v-text-field--filled v-text-field--is-booted v-text-field--enclosed',
      _vm.hasError ? 'error--text' : '',
      _vm.focused ? 'v-input--is-focused' : ''
    ]},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot"},[_c('label',{class:[
              'v-label v-label--active theme--light',
              _vm.hasError ? 'error--text' : 'primary--text'
            ],staticStyle:{"left":"0","right":"auto","position":"absolute"},attrs:{"id":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('editor-content',{style:(_vm.styles),attrs:{"aria-labelledby":_vm.id,"aria-label":_vm.ariaLabel,"editor":_vm.editor},on:{"focus":_vm.onFocus,"blur":_vm.onBlur}})],1)]),(!_vm.hideDetails)?_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},_vm._l((_vm.errors),function(e,idx){return _c('div',{key:idx,staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(e)+" ")])}),0)])]):_vm._e()])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }