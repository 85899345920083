<template>
  <v-footer
    :height=height
    app
    class="px-0"
    inset
  >
    <v-card
      class="text-center"
      flat
      tile
    >
      <v-container
        v-if="showActAsAdminFooter"
        class="info-footer"
        fluid
      >
        <v-row>
          <v-col>
            {{ $t('components.navigation.footer.adminActsAsUser') }}
          </v-col>
        </v-row>
      </v-container>

      <v-card-text>
        <v-dialog
          v-model="meta"
          max-width="650"
        >
          <keep-alive>
            <component
              :is="modal"
            />
          </keep-alive>
        </v-dialog>
        <v-row class="to-footer">
          <a href="#"
             @click="open($event, 'imprint')"
          >{{ $t('components.footer.imprint') }}</a>
          <span>|</span>
          <a href="#"
             @click="open($event, 'data-protection')"
          >{{ $t('components.footer.dataProtection') }}</a>
          <span>|</span>
          <a href="#"
             @click="showCookieConsent = true"
          >{{ $t('components.footer.cookies') }}</a>
          <v-spacer/>
          <a href="https://www.trendone.com/"
             target="_blank">&copy; Trendone GmbH</a>
        </v-row>
      </v-card-text>
    </v-card>
    <cookie-consent v-model="showCookieConsent"/>
  </v-footer>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import LoadingCircle from '@/components/loading-circle/loading-circle'

import { GET_ADMIN_ACTS_AS_USER, GET_LOCALE } from '@/store/auth/getter-types'

import URLS from '@/model/help/urls'
import { SHOW_DATA_PROTECTION } from '@/model/events'
import CookieConsent from '@/components/consent-management/cookie-consent/index.vue'

const auth = namespace('auth')

@Component({
  components: { CookieConsent }
})
export default class NavigationFooter extends Vue {
  @auth.Getter(GET_LOCALE) locale
  @auth.Getter(GET_ADMIN_ACTS_AS_USER) adminActsAsUser

  meta = false
  modal = false
  showCookieConsent = false

  components = {
    'de-imprint': () => ({
      component: import(/* webpackChunkName: "imprint-de" */ '@/components/navigation/footer/meta/de/imprint.vue'),
      loading: LoadingCircle
    }),
    'de-data-protection': () => ({
      component: import(/* webpackChunkName: "data-protection-de" */ '@/components/navigation/footer/meta/de/data-protection.vue'),
      loading: LoadingCircle
    }),
    'en-imprint': () => ({
      component: import(/* webpackChunkName: "imprint-en" */ '@/components/navigation/footer/meta/en/imprint.vue'),
      loading: LoadingCircle
    }),
    'en-data-protection': () => ({
      component: import(/* webpackChunkName: "data-protection-en" */ '@/components/navigation/footer/meta/en/data-protection.vue'),
      loading: LoadingCircle
    })
  }

  cookiesConsented () {
    // @ts-ignore
    return 'kjlasdjflkasd'
  }

  get showActAsAdminFooter () {
    return this.adminActsAsUser
  }

  get breakpoint () {
    return this.$vuetify.breakpoint.mdAndDown
  }

  get helpUrl () {
    return URLS[this.locale]
  }

  get height () {
    return this.showActAsAdminFooter
      ? 92
      : 50
  }

  open (evt, what) {
    this.modal = this.components[`${this.locale}-${what}`]
    this.meta = true
  }

  close () {
    this.meta = false
  }

  beforeDestroy () {
    this.$root.$off(SHOW_DATA_PROTECTION, this.showDataProtection)
  }

  mounted () {
    this.showDataProtection = () => {
      this.open(null, 'data-protection')
    }
    this.$root.$on(SHOW_DATA_PROTECTION, this.showDataProtection)
  }
}
</script>
