export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN'
export const ROLE_ADMIN = 'ROLE_ADMIN'
export const ROLE_TREND_MANAGER = 'ROLE_TREND_MANAGER'
export const ROLE_USER = 'ROLE_USER'

export const ROLES_MAP = {
  [ROLE_SUPER_ADMIN]: 'Super-Admin',
  [ROLE_ADMIN]: 'Admin',
  [ROLE_TREND_MANAGER]: 'Manager',
  [ROLE_USER]: 'User'
}

const ROLE_CONTAINS: { [key: string]: string[] } = {
  [ROLE_SUPER_ADMIN]: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_TREND_MANAGER, ROLE_USER],
  [ROLE_ADMIN]: [ROLE_ADMIN, ROLE_TREND_MANAGER, ROLE_USER],
  [ROLE_TREND_MANAGER]: [ROLE_TREND_MANAGER, ROLE_USER],
  [ROLE_USER]: [ROLE_USER]
}

export const hasRole = (currentRoles: string[], requiredRole: string) => {
  let result = false

  currentRoles.forEach(currentRole => {
    if (currentRole in ROLE_CONTAINS && ROLE_CONTAINS[currentRole].includes(requiredRole)) {
      result = true
    }
  })

  return result
}

export const COMPANY_USER_ROLES_LIST = [
  ROLE_TREND_MANAGER,
  ROLE_USER
]

export const UNEDITABLE_ROLES = [
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN
]
