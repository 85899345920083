export const LOGOUT = 'LOGOUT'
export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const UPDATE_AVATAR = 'UPDATE_AVATAR'
export const CHANGE_LOCALE = 'CHANGE_LOCALE'
export const RESET = 'RESET'
export const SET_PENDING = 'SET_PENDING'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const INIT_2FA = 'INIT_2FA'
