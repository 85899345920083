import store from '@/store'
import { namespacer } from '@/util/namespacer'
import { UPDATE_PAGINATION } from '@/store/gui/mutation-types'

export const viewPagination = (to, from, next) => {
  if (to.meta.view) {
    const id = to.meta.view
    const pageInfo = {}
    if (to.query.page) {
      pageInfo.currentPage = to.query.page
    }

    store.commit(namespacer('gui', UPDATE_PAGINATION), { id, pageInfo })
  }

  next()
}
