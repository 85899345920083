export const ANY_OPEN = 'anyOpen'
export const GET_CURRENT_WORKSPACE = 'getCurrentWorkspace'
export const GET_WORKSPACE_EDIT = 'getWorkspaceEdit'
export const IS_DELETE = 'isDelete'
export const IS_LEAVE = 'isLeave'
export const IS_REMOVE_NODE = 'isRemoveNode'
export const IS_COPY = 'isCopy'
export const GET_SORT = 'GET_SORT'
export const GET_XLSX_EXPORT_URL = 'GET_XLSX_EXPORT_URL'
export const PENDING = 'PENDING'
