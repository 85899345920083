import * as ACTION from './action-types'
import { ADD_ENTRY } from './mutation-types'
import { appClient } from '@/plugins/apollo'
import loadFacetsQuery from '@/graphql/app/trendfinder/query/facets-by-id.gql'
import { FacetFactory } from '@/util/Facets'
import { namespacer } from '@/util/namespacer'
import { GET_LOCALE } from '@/store/auth/getter-types'

export default {
  [ACTION.LOAD_FACETS]: ({ commit, rootGetters }, payload) => {
    // commit(RESET)
    appClient.query({
      query: loadFacetsQuery,
      variables: {
        ids: payload,
        locale: rootGetters[namespacer('auth', GET_LOCALE)]
      }
    }).then(data => {
      const facets = FacetFactory.createEntriesFromNodes(data.data.nodes)
      facets.forEach(facet => {
        commit(ADD_ENTRY, facet)
      })
    })
  }
}
