import { Locale } from '@/graphql/app/types'
import dayjs from 'dayjs'

export function formattedDate (locale: Locale, date?: string): string {
  if (!date) {
    return ''
  }

  return dayjs(date).locale(locale).format('ll')
}
