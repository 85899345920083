export const INNOVATION_FIELDS = 'INNOVATION_FIELDS'
export const INNOVATION_PROJECTS = 'INNOVATION_PROJECTS'
export const TRENDFINDER_MACROTRENDS = 'TRENDFINDER_MACROTRENDS'
export const TRENDFINDER_MEGATRENDS = 'TRENDFINDER_MEGATRENDS'
export const TRENDFINDER_MICROTRENDS = 'TRENDFINDER_MICROTRENDS'
export const TRENDRADAR_RADARS = 'TRENDRADAR_RADARS'
export const TRENDRADAR_PARTICIPATIONS = 'TRENDRADAR_PARTICIPATIONS'
export const WORKSPACES = 'WORKSPACES'
export const INSIGHTS_TRENDCALLS = 'INSIGHTS_TRENDCALLS'
export const INSIGHTS_TRENDING_TOPICS = 'INSIGHTS_TRENDING_TOPICS'
export const INSIGHTS_TREND_SUMMARIES = 'INSIGHTS_TREND_SUMMARIES'
export const INSIGHTS_TREND_BRIEFINGS = 'INSIGHTS_TREND_BRIEFINGS'
