<template>
  <div
    v-if="show"
    :class="classes"
  >
    <v-tooltip
      v-if="small"
      color="primary"
      left
    >
      <template
        v-slot:activator="{ on }"
      >
        <to-icon
          v-on="on"
          color="primary"
          size="18px"
        >
          signal
        </to-icon>
      </template>
      <span>{{ title }}</span>
    </v-tooltip>
    <v-list
      v-else
      dense
    >
      <v-list-item
        dense
      >
        <v-list-item-action
        >
          <to-icon
            color="primary"
            size="18px"
          >
            signal
          </to-icon>
        </v-list-item-action>
        <v-list-item-title>
          {{ title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { COMPANY_MICROTREND, MICROTRENDS } from '@/model/types'
import ToIcon from '@/components/to-icon/to-icon'

@Component({
  components: { ToIcon }
})
export default class CompanyMacrotrendMarker extends Vue {
  @Prop({
    type: Object,
    validator: obj => MICROTRENDS.includes(obj.__typename)
  }) obj

  @Prop({
    type: String,
    default: () => null
  }) title

  @Prop({
    type: Boolean,
    default: () => false
  }) small

  get show () {
    return this.obj.__typename === COMPANY_MICROTREND
  }

  get classes () {
    return `company-microtrend-marker ${this.small ? 'small' : 'big'}`
  }
}
</script>
