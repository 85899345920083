<template>
  <confirmation-dialog
    v-if="show && searchAgent && workspace"
    v-model="show"
    :pending="pending"
    :title="$t('components.searchAgent.deletion.question', { title: searchAgent.title })"
    :notice="$t('components.searchAgent.deletion.notice')"
    :confirm="$t('components.searchAgent.deletion.action')"
    @confirmed="onConfirmed"
  />
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import deleteSearchAgentGQL from '@/graphql/app/search-agent/mutation/delete.gql'

import ConfirmationDialog from '@/components/confirmation-dialog/confirmation-dialog'
import { HIDE_ALL_DIALOGS } from '@/store/workspace/mutation-types'
import { SHOW_SNACKBAR } from '@/store/gui/mutation-types'
import { GET_CURRENT_SEARCH_AGENT, GET_CURRENT_WORKSPACE, SHOW_DELETE } from '@/store/search-agent/getter-types'

const guiStore = namespace('gui')
const searchAgentStore = namespace('searchAgent')

@Component({
  components: {
    ConfirmationDialog
  }
})
export default class SearchAgentDeleteDialog extends Vue {
  pending = false

  @guiStore.Mutation(SHOW_SNACKBAR) showSnackbar

  @searchAgentStore.Getter(SHOW_DELETE) delete
  @searchAgentStore.Getter(GET_CURRENT_SEARCH_AGENT) searchAgent
  @searchAgentStore.Getter(GET_CURRENT_WORKSPACE) workspace
  @searchAgentStore.Mutation(HIDE_ALL_DIALOGS) hide

  get show () {
    return this.delete
  }

  set show (value) {
    if (!value) {
      this.hide()
    }
  }

  async onConfirmed () {
    try {
      this.pending = true
      this.$track('SearchAgent', 'delete')
      await this.$apollo.mutate({
        mutation: deleteSearchAgentGQL,
        variables: {
          workspaceId: this.workspace.id,
          searchAgentId: this.searchAgent.id
        }
      })

      this.showSnackbar(this.$t('components.searchAgent.deletion.success'))

      await this.$router.push({
        name: 'workspace-detail',
        params: {
          workspaceId: this.workspace.id
        }
      })

      this.hide()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('%cError on delete search-agent:', 'color: red;', error)
    } finally {
      this.pending = false
    }
  }
}
</script>
