import * as MUTATION from './mutation-types'
import Vue from 'vue'

export default {
  [MUTATION.SET]: (state, payload) => {
    if (!(payload.type in state)) {
      Vue.set(state, payload.type, {})
    }
    Vue.set(state[payload.type], payload.name, payload.value)
  },
  [MUTATION.REMOVE]: (state, payload) => {
    if (payload.type in state) {
      if (payload.name in state[payload.type]) {
        Vue.delete(state[payload.type], payload.name)
      }
      if (Object.keys(state[payload.type]).length === 0) {
        Vue.delete(state, payload.type)
      }
    }
  }
}
