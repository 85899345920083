
import { Component, Prop, Vue } from 'vue-property-decorator'
import Avatar from '@/components/person/avatar.vue'

@Component({
  components: { Avatar }
})
export default class UserSelectionMultiple extends Vue {
  @Prop({ type: Object, required: true }) data!: object
  @Prop({ type: Number, required: true }) length!: number

  get limit (): number {
    return 2
  }

  get hint (): string {
    return this.t('counter', { count: this.length }, this.length)
  }

  t (token: string, props: Record<string, any> = {}, cnt: number | null = null): string {
    const prefix = 'components.form.userSelect'
    return cnt === null
      ? this.$t(`${prefix}.${token}`, props).toString()
      : this.$tc(`${prefix}.${token}`, cnt, props).toString()
  }
}
