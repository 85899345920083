
import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator'
import { Editor, EditorContent } from '@tiptap/vue-2'

import StarterKit from '@tiptap/starter-kit'
import { Underline } from '@tiptap/extension-underline'
import { Link } from '@tiptap/extension-link'
import { Placeholder } from '@tiptap/extension-placeholder'
import { uid } from '@/util/uid'

@Component({
  components: {
    EditorContent
  }
})
export default class TiptapEditor extends Vue {
  @VModel() content!: string

  @Prop({ default: () => '' }) ariaLabel!: string
  @Prop({ default: () => false }) hideDetails!: boolean
  @Prop({ default: () => '' }) label!: string
  @Prop({ default: () => '' }) placeholder!: string
  @Prop({ default: () => [] }) errorMessages!: Array<string> | string

  editor: Editor | null = null
  uid = ''
  focused = false

  get id (): string {
    return `wysiwyg-tiptap-${this.uid}`
  }

  get styles (): Record<string, string> {
    return {
      'margin-top': this.label !== '' ? '25px' : '0',
      padding: '12px 0',
      width: '100%'
    }
  }

  get errors (): Array<string> {
    return Array.isArray(this.errorMessages)
      ? this.errorMessages
      : [this.errorMessages]
  }

  get hasError (): boolean {
    return this.errors.length > 0
  }

  textFormat (editor: Editor): Array<number> {
    const result = []

    if (editor.isActive('bold')) {
      result.push(0)
    }

    if (editor.isActive('italic')) {
      result.push(1)
    }

    if (editor.isActive('underline')) {
      result.push(2)
    }

    return result
  }

  listFormat (editor: Editor): Array<number> {
    const result = []

    if (editor.isActive('orderedList')) {
      result.push(0)
    }

    if (editor.isActive('bulletedList')) {
      result.push(1)
    }

    return result
  }

  misc (editor: Editor): Array<number> {
    const result = []

    if (editor.isActive('link')) {
      result.push(0)
    }

    return result
  }

  setLink (editor: Editor) {
    const previousUrl = editor.getAttributes('link').href
    let url: string | null = window.prompt('URL', previousUrl)

    if (url === null) {
      return
    }

    if (url === '') {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .unsetLink()
        .run()

      return
    }

    url = url.toLowerCase()
    if (!url.startsWith('tel') && !url.startsWith('mailto') && !url.startsWith('http')) {
      url = `https://${url}`
    }

    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run()
  }

  @Watch('content')
  onContentChange (value: string): void {
    if (this.editor !== null) {
      if (this.editor.getHTML() === value) {
        return
      }
      this.editor.commands.setContent(value, false)
    }
  }

  onFocus (): void {
    console.log('onFocus')
  }

  onBlur (): void {
    console.log('onBlur')
  }

  mounted () {
    this.uid = uid()
    this.editor = new Editor({
      content: this.content,
      extensions: [
        StarterKit,
        Underline,
        Link.configure({
          protocols: ['https', 'http', 'tel', 'mailto'],
          autolink: true,
          linkOnPaste: true,
          openOnClick: false
        }),
        Placeholder.configure({
          placeholder: this.placeholder
        })
      ],
      onFocus: () => {
        this.focused = true
      },
      onBlur: () => {
        this.$emit('blur')
        this.focused = false
      },
      onUpdate: () => {
        if (this.editor !== null) {
          this.$emit('update')
          this.content = this.editor.isEmpty ? '' : this.editor.getHTML()
        }
      }
    })
  }

  beforeDestroy () {
    if (this.editor !== null) {
      this.editor.destroy()
    }
  }
}

