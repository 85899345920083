import Vue from 'vue'
import * as MUTATION from './mutation-types'
import { initChart, initView } from './state'

const mutations = {
  [MUTATION.INCREMENT_RELOAD_POST_CNT]: (state, payload) => {
    Vue.set(state, 'reloadPostsCnt', state.reloadPostsCnt + 1)
    Vue.set(state, 'reloadPostsType', payload)
  },

  [MUTATION.RESET_DETAILS]: (state) => {
    const d = initView()
    Vue.set(state, 'details', d.details)
    Vue.set(state, 'highlight', d.highlight)
  },

  [MUTATION.RESET_RELOAD_POSTS_CNT]: (state) => {
    Vue.set(state, 'reloadPostsCnt', 0)
    Vue.set(state, 'reloadPostsType', null)
  },

  [MUTATION.SET_DETAILS]: (state, payload) => {
    Vue.set(state, 'details', payload)
  },

  [MUTATION.SET_MODE]: (state, payload) => {
    Vue.set(state, 'mode', payload)
  },

  [MUTATION.SET_HIGHLIGHT]: (state, payload) => {
    Vue.set(state, 'highlight', payload)
  },

  [MUTATION.CHANGE_VIEW]: (state, payload) => {
    Vue.set(state, 'view', payload)
  },

  [MUTATION.CHANGE_CHART_CONFIGURATION]: (state, payload) => {
    Vue.set(state, 'chartConfiguration', payload)
  },

  [MUTATION.INIT_CHART_CONFIGURATION]: (state, payload) => {
    state.criteriaSet = {
      ...payload
    }
    state.chartConfiguration = initChart(payload.criterias)
  },

  [MUTATION.SET_FILTER]: (state, payload) => {
    state.filter = [
      ...payload
    ]
  }
}

export default mutations
