<template>
  <div>
    <div>
      <p class="d-inline">
        {{ $t('components.microtrend.overlay.macrotrends') }}
      </p>
      <v-btn
        v-if="$can('update', microtrend)"
        :disabled="pending"
        small
        rounded
        icon
        text
        color="primary"
        class="ma-0 float-right"
        @click="onClick"
      >
        <to-icon>check</to-icon>
      </v-btn>
    </div>
    <loading-circle
      v-if="pending"
      class="pa-3"
      size="30"
    />
    <div
      style="width: 90%"
      v-else
    >
      <macrotrend-autocomplete
        v-model="internal"
        :blacklist="pinnedMacrotrendIds"
        chips
      />
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { MICROTRENDS } from '@/model/types'
import { GET_LOCALE } from '@/store/auth/getter-types'
import { LIST } from '@/model/views/modes'

import LoadingCircle from '@/components/loading-circle/loading-circle'
import MacrotrendAutocomplete from '@/components/form/autocomplete/macrotrend'
import ToIcon from '@/components/to-icon/to-icon'

const authStore = namespace('auth')

@Component({
  components: {
    LoadingCircle,
    MacrotrendAutocomplete,
    ToIcon
  }
})
export default class MacrotrendsEdit extends Vue {
  @Prop({
    type: Object,
    validator: obj => MICROTRENDS.includes(obj.__typename),
    required: true
  }) microtrend

  @Prop({ type: Array }) value

  @Prop({
    type: Boolean,
    default: () => false
  }) pending

  @Prop({
    type: String,
    required: true
  }) mode

  @authStore.Getter(GET_LOCALE) locale

  get internal () {
    return this.value
  }

  set internal (value) {
    this.$emit('input', value)
  }

  get pinnedMacrotrendIds () {
    return this.microtrend.pins
      ? this.microtrend.pins.map(elem => elem.macrotrend.id)
      : []
  }

  onClick () {
    this.$emit('submit')
  }

  @Watch('pending')
  onChangePending (n) {
    if (n === false && this.mode !== LIST) {
      this.$emit('change-mode', LIST)
    }
  }
}
</script>

<!-- check
<style lang="stylus"
       scoped>
>>> .input-group
  &.input-group--chips
    padding-top: 10px !important
</style>
-->
