import * as MUTATION from './mutation-types'
import Vue from 'vue'
import { initState } from './state'
// import { ALLOWED_LOCALES } from '@/plugins/i18n'
import { COMPANY } from '@/model/types'

export default {
  [MUTATION.LOGIN]: (state) => {
    const fresh = initState(state.locale)
    state = {
      ...fresh,
      pending: true
    }
  },

  [MUTATION.LOGIN_SUCCESS]: (state, payload) => {
    Vue.set(state, 'rules', payload.rules)
    if (payload.tokenExpirationDate) {
      Vue.set(state, 'tokenExpirationDate', payload.tokenExpirationDate)
    }
    if (payload.trackingId) {
      Vue.set(state, 'trackingId', payload.trackingId)
    }
    Vue.set(state, 'user', payload.user)
    Vue.set(state, 'pending', false)
    Vue.set(state, 'redirectHash', null)
  },

  [MUTATION.LOGIN_FAILURE]: (state) => {
    Vue.set(state, 'pending', false)
  },

  [MUTATION.LOGOUT]: (state) => {
    const fresh = initState(state.locale)
    Vue.set(state, 'rules', fresh.rules)
    Vue.set(state, 'user', fresh.user)
    Vue.set(state, 'pending', false)
    Vue.set(state, 'locale', fresh.locale)
    Vue.set(state, 'tokenExpirationDate', fresh.tokenExpirationDate)
    Vue.set(state, 'trackingId', fresh.trackingId)
    Vue.set(state, 'redirectHash', null)
  },

  [MUTATION.UPDATE_AVATAR]: (state, payload) => {
    Vue.set(state.user, 'avatar', payload)
  },

  [MUTATION.CHANGE_LOCALE]: (state, payload) => {
    Vue.set(state, 'locale', payload)
  },

  [MUTATION.SET_PENDING]: (state, payload) => {
    Vue.set(state, 'pending', payload)
  },

  [MUTATION.UPDATE_COMPANY]: (state, payload) => {
    if (payload.__typename !== COMPANY) {
      throw new Error('Invalid data type')
    }

    Vue.set(state.user, 'company', {
      ...state.user.company,
      ...payload
    })
  },

  [MUTATION.INIT_2FA]: (state, payload) => {
    if (!payload) {
      throw new Error('Invalid hash')
    }

    Vue.set(state, 'redirectHash', payload)
    Vue.set(state, 'pending', false)
  }
}
