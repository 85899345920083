
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { GET_LOCALE } from '@/store/auth/getter-types'

import EditableTrendTag from '@/components/form/editable-trend-tag/editable-trend-tag.vue'
import { Locale, MacrotrendsQuery, useMacrotrendsQuery } from '@/graphql/app/types'
import { PropType } from 'vue'

const authStore = namespace('auth')

export type OptionMacrotrend = { id: string, megatrend?: { id: string, color?: string | null }, translations?: Array<{ title?: string | null } | null> }

@Component<MacrotrendAutocomplete>({
  apollo: {
    macrotrends: useMacrotrendsQuery<MacrotrendAutocomplete>({
      fetchPolicy: 'network-only',
      variables () {
        return {
          locale: this.locale
        }
      }
    })
  },
  components: {
    EditableTrendTag
  }
})
export default class MacrotrendAutocomplete extends Vue {
  @Prop({
    type: Array,
    default: () => []
  }) value!: Array<string>

  @Prop({
    type: String,
    default: () => null
  }) label!: string | null

  @Prop({
    type: Boolean,
    default: () => false
  }) chips!: boolean

  @Prop({
    type: Boolean,
    default: () => false
  }) loading!: boolean

  @Prop({
    type: Array,
    default: () => []
  }) blacklist!: Array<string>

  @Prop({
    type: Array as PropType<Array<OptionMacrotrend>>,
    validator: (value: unknown): boolean => {
      // @ts-ignore
      return value.every((item: unknown) => !!item?.megatrend?.color)
    },
    default: () => []
  }) extraOptions!: Array<OptionMacrotrend>

  @authStore.Getter(GET_LOCALE) locale!: Locale

  macrotrends: MacrotrendsQuery['macrotrends'] = []

  get load () {
    return this.$apollo.queries.macrotrends.loading || this.loading
  }

  get internal () {
    return this.value
  }

  set internal (value: Array<string>) {
    this.$emit('input', value)
  }

  get items () {
    const itemIds = this.macrotrends.map(macrotrend => macrotrend.id)

    this.extraOptions.forEach((option) => {
      if (!itemIds.includes(option.id)) {
        this.macrotrends.push(option)
      }
    })

    return this.macrotrends
      .map((elem) => ({
        value: elem.id,
        text: elem.translations && elem.translations[0] ? elem.translations[0].title as string : '',
        disabled: this.blacklisted(elem.id)
      }))
      .sort((a, b) => a.text.localeCompare(b.text))
  }

  reset () {
    // @ts-ignore
    this.$refs.el.lazySearch = ''
  }

  blacklisted (id: string) {
    return this.blacklist.includes(id)
  }

  macrotrend (id: string) {
    return this.macrotrends.find(elem => elem.id === id)
  }

  onRemove (remove: string) {
    this.$emit('input', this.value.filter(elem => elem !== remove))
  }
}
