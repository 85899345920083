<template>
  <v-list-item
    dense
  >
    <v-list-item-action>
      <v-checkbox
        @click.stop.prevent="checked = !checked"
        :input-value="checked"
        :key="entry.id"
      />
    </v-list-item-action>
    <v-list-item-title>{{ entry.title }}</v-list-item-title>
  </v-list-item>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SearchFacetChild extends Vue {
  @Prop({
    type: Object,
    required: true,
    validate: (value) => value && value.id && value.title
  }) entry

  @Prop({
    type: Object,
    default: () => {
    }
  }) state

  add () {
    this.$emit('addChild', this.entry)
  }

  remove () {
    this.$emit('removeChild', this.entry)
  }

  get isChecked () {
    return this.state && this.entry.id in this.state
  }

  get checked () {
    return this.isChecked
  }

  set checked (value) {
    if (this.isChecked) {
      this.remove(this.entry)
    } else {
      this.add(this.entry)
    }
  }
}
</script>
