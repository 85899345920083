<template>
  <v-dialog
    v-model="show"
    max-width="650"
  >
    <loading-circle v-if="loading" />
    <search-agent-form
      v-else
      @submit="onSubmit"
      @close="onCloseOverlay"
      v-model="searchAgent"
      :pending="pending"
      :workspace="workspace"
    />
  </v-dialog>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import LoadingCircle from '@/components/loading-circle/loading-circle'
import SearchAgentForm from '@/components/search-agent/edit/form'

import searchAgentGQL from '@/graphql/app/search-agent/query/details.gql'
import updateGQL from '@/graphql/app/search-agent/mutation/update.gql'
import createGQL from '@/graphql/app/search-agent/mutation/create.gql'

import { empty } from '@/model/search-agent/initial'

import { FacetFactory } from '@/util/Facets'
import { HIDE_ALL_DIALOGS } from '@/store/search-agent/mutation-types'
import { GET_LOCALE } from '@/store/auth/getter-types'
import { GET_CURRENT_SEARCH_AGENT, GET_CURRENT_WORKSPACE, SHOW_EDIT } from '@/store/search-agent/getter-types'
import { SHOW_SNACKBAR } from '@/store/gui/mutation-types'
import { SEARCH_AGENT_ADDED } from '@/model/events'

const authStore = namespace('auth')
const guiStore = namespace('gui')
const searchAgentStore = namespace('searchAgent')

@Component({
  apollo: {
    searchAgent: {
      fetchPolicy: 'cache-and-network',
      query: searchAgentGQL,
      variables () {
        return {
          id: this.searchAgentId,
          workspaceId: this.workspace.id,
          locale: this.locale
        }
      },
      update (data) {
        return {
          title: data.searchAgent.title,
          search: {
            query: data.searchAgent.search.query,
            facetState: this.initialFacetState(data.searchAgent.facets)
          }
        }
      },
      skip () {
        return !this.searchAgentId || this.searchAgent.id === this.searchAgentId
      }
    }
  },
  components: {
    LoadingCircle,
    SearchAgentForm
  }
})
export default class SearchAgentEdit extends Vue {
  searchAgent = empty()
  pending = false

  @authStore.Getter(GET_LOCALE) locale

  @guiStore.Mutation(SHOW_SNACKBAR) showSnackbar

  @searchAgentStore.Getter(SHOW_EDIT) edit
  @searchAgentStore.Getter(GET_CURRENT_SEARCH_AGENT) currentSearchAgent
  @searchAgentStore.Getter(GET_CURRENT_WORKSPACE) workspace
  @searchAgentStore.Mutation(HIDE_ALL_DIALOGS) closeDialog

  get show () {
    return this.edit
  }

  set show (_) {
    this.onCloseOverlay()
  }

  get loading () {
    return this.$apollo.queries.searchAgent.loading
  }

  get buildFilter () {
    const filter = {}
    for (const f of Object.values(this.searchAgent.search.facetState)) {
      if (typeof filter[f.filter] === 'undefined') {
        filter[f.filter] = []
      }
      filter[f.filter].push(f.id)
    }

    delete filter.__typename

    return filter
  }

  get searchAgentId () {
    return this.currentSearchAgent ? this.currentSearchAgent.id : null
  }

  onCloseOverlay () {
    this.searchAgent = empty()
    this.closeDialog()
  }

  async onSubmit () {
    if (this.searchAgentId) {
      await this.update()
    } else {
      await this.create()
    }
  }

  async create () {
    try {
      this.pending = true
      const result = await this.$apollo.mutate({
        mutation: createGQL,
        variables: {
          input: {
            title: this.searchAgent.title,
            search: {
              query: this.searchAgent.search.query,
              filter: this.searchAgent.search.facetState ? this.buildFilter : null
            },
            workspaces: [this.workspace.id]
          }
        }
      })

      this.showSnackbar(
        this.$t('components.searchAgent.edit.success.create', { title: result.data.createSearchAgent.title })
      )
      this.$root.$emit(SEARCH_AGENT_ADDED)
      this.onCloseOverlay()
    } catch (error) {
      console.log('%cError on creating search agent: ', 'color: red;', error) // eslint-disable-line no-console
    } finally {
      this.pending = false
    }
  }

  async update () {
    try {
      this.pending = true

      const result = await this.$apollo.mutate({
        mutation: updateGQL,
        variables: {
          workspaceId: this.workspace.id,
          locale: this.locale,
          input: {
            id: this.searchAgentId,
            title: this.searchAgent.title,
            search: {
              query: this.searchAgent.search.query,
              filter: this.searchAgent.search.facetState ? this.buildFilter : null
            }
          }
        }
      })

      this.showSnackbar(
        this.$t('components.searchAgent.edit.success.update', { title: result.data.updateSearchAgent.title })
      )
      this.onCloseOverlay()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('%cError on update search-agent)', 'color: red;', error)
    } finally {
      this.pending = false
    }
  }

  initialFacetState (facets) {
    const initializedState = {}

    if (facets !== null) {
      for (const k of Object.keys(facets)) {
        if (facets[k] !== null) {
          for (const f of facets[k]) {
            const instance = FacetFactory.createEntryInstance(k, f)
            if (instance !== null) {
              initializedState[instance.id] = instance
            }
          }
        }
      }
      return initializedState
    }

    return {}
  }
}
</script>
