import { runtimeConfig } from '@/util/runtime-config'
import { Component, Vue } from 'vue-property-decorator'

export const oauthURI = () => runtimeConfig('VUE_APP_OAUTH_INITIATOR_URI', undefined)
export const ssoEnabled = () => (oauthURI() !== undefined)

@Component
export default class AzureSso extends Vue {
  get ssoEnabled () {
    return ssoEnabled()
  }

  get oauthURI () {
    return oauthURI()
  }
}
