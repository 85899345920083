import store from '@/store'
import { SET_QUERY } from '@/store/search/mutation-types'
import { LOAD_FACETS } from '@/store/search/action-types'
import { namespacer } from '@/util/namespacer'

export const trendfinder = (to, from, next) => {
  if (to.name.indexOf('trendfinder') !== -1) {
    if (to.query.f) {
      store
        .dispatch(namespacer('search', LOAD_FACETS), to.query.f.split(','), { root: true })
        .then(() => {
          if (to.query.q) {
            store.commit(namespacer('search', SET_QUERY), to.query.q)
          }
          next()
        })
    } else {
      if (to.query.q) {
        store.commit(namespacer('search', SET_QUERY), to.query.q)
      }
      next()
    }
  } else {
    next()
  }
}
