export const themeOptions = {
  themes: {
    light: {
      primary: '#1620D1',
      accent: '#FF5252', // colors.red.accent2,
      secondary: '#BDBDBD', // colors.grey.lighten1,
      info: '#47e6a3',
      warning: '#FFA000', // colors.amber.darken2,
      error: '#E7001C',
      alert: '#e91e63',
      success: '#81C784', // colors.green.lighten2,
      grey: '#515061',
      'light-grey': '#a09fa8',
      'lightgrey-bg': '#eeeeee',
      'default-background': '#f2f2f2'
    },
    dark: {
      primary: '#1620D1',
      accent: '#FF5252', // colors.red.accent2,
      secondary: '#BDBDBD', // colors.grey.lighten1,
      info: '#42A5F5',
      warning: '#FFA000', // colors.amber.darken2,
      error: '#E7001C',
      alert: '#e91e63',
      success: '#81C784', // colors.green.lighten2,
      grey: '#515061',
      'light-grey': '#a09fa8',
      'lightgrey-bg': '#eeeeee',
      'default-background': '#f2f2f2'
    }
  }
}
