export const state = {
  loading: false,
  pending: false,
  participation: {
    id: null,
    evaluationEnabled: false,
    title: null,
    description: null,
    criteriaSet: null,
    evaluations: []
  },
  participationCompleted: false
}
