import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import gql from 'graphql-tag'
import * as FEATURE from '@/model/views/features'
import * as TYPE from '@/model/types'
import { IS_EWE_USER, IS_TRENDEXPLORER_COMPANY } from '@/store/auth/getter-types'

const authStore = namespace('auth')

@Component({
  apollo: {
    optCounter: {
      fetchPolicy: 'network-only',
      query () {
        const innovationFields = 'innovationFields(page: 1, pageSize: 1) {pageInfo {totalCount}}, '
        const innovationProjects = 'innovationProjects(page: 1, pageSize: 1) {pageInfo {totalCount}}, '
        const trendRadars = 'trendRadars(page: 1, pageSize: 1) {nodes {id} pageInfo {totalCount}}, trendRadarParticipations(page: 1, pageSize: 1) {nodes {id} pageInfo {totalCount}}, '

        let queryBody = ''

        if (this.$can('use-feature', FEATURE.TRENDRADAR) || this.$can('read', TYPE.TRENDRADAR_PUBLISHED)) {
          queryBody += trendRadars
        }

        if (this.$can('use-feature', FEATURE.INNOVATION_FIELD)) {
          queryBody += innovationFields
        }

        if (this.$can('use-feature', FEATURE.INNOVATION_PROJECT)) {
          queryBody += innovationProjects
        }

        return gql`
          ${`query CountEntities {${queryBody} }`}
        `
      },
      skip () {
        return !(this.$can('use-feature', FEATURE.TRENDRADAR) ||
          this.$can('use-feature', FEATURE.INNOVATION_FIELD) ||
          this.$can('use-feature', FEATURE.INNOVATION_PROJECT) ||
          this.isTrendExplorerCompany
        )
      },
      update (data) {
        return {
          innovationFields: data.innovationFields ? data.innovationFields.pageInfo.totalCount : 0,
          trendRadars: data.trendRadars ? data.trendRadars.pageInfo.totalCount : 0,
          trendRadarParticipations: data.trendRadarParticipations ? data.trendRadarParticipations.pageInfo.totalCount : 0,
          trendRadarId: data.trendRadars && Array.isArray(data.trendRadars.nodes) && data.trendRadars.nodes.length === 1
            ? data.trendRadars.nodes[0].id
            : null,
          trendRadarParticipationId: data.trendRadarParticipations && Array.isArray(data.trendRadarParticipations.nodes) && data.trendRadarParticipations.nodes.length === 1
            ? data.trendRadarParticipations.nodes[0].id
            : null,
          innovationProjects: data.innovationProjects ? data.innovationProjects.pageInfo.totalCount : 0
        }
      }
    }
  }
})
export default class OptionalMixin extends Vue {
  @authStore.Getter(IS_EWE_USER) isEweUser
  @authStore.Getter(IS_TRENDEXPLORER_COMPANY) isTrendExplorerCompany

  optCounter = {
    innovationFields: 0,
    trendRadars: 0,
    trendRadarParticipations: 0,
    trendRadarId: null,
    trendRadarParticipationId: null,
    innovationProjects: 0
  }

  get optShowInnovationFields () {
    return this.$can('create', TYPE.INNOVATION_FIELD) || this.optCounter.innovationFields > 0
  }

  get optTrendRadarTarget () {
    if (this.$can('create', TYPE.TRENDRADAR_DRAFT)) {
      return { name: 'trendradar-trendradars' }
    }

    if (this.optCounter.trendRadars === 0) {
      if (this.optCounter.trendRadarParticipations === 0) {
        return null
      }

      if (this.optCounter.trendRadarParticipations === 1) {
        return {
          name: 'trendradar-participation',
          params: { participationId: this.optCounter.trendRadarParticipationId }
        }
      }

      return { name: 'trendradar-participations' }
    }

    if (this.optCounter.trendRadars === 1 && this.optCounter.trendRadarParticipations === 0) {
      return {
        name: 'trendradar-view',
        params: { trendradarId: this.optCounter.trendRadarId }
      }
    }

    return { name: 'trendradar-trendradars' }
  }

  get optShowTrendRadars () {
    return this.optTrendRadarTarget !== null
  }

  get optShowInnovationProjects () {
    return this.$can('create', TYPE.INNOVATION_PROJECT) ||
      this.isEweUser ||
      this.optCounter.innovationProjects > 0
  }

  get optLoading () {
    return this.$apollo.queries.optCounter.loading
  }
}
