import { ApiError, VALIDATION_ERROR_CODE } from '@/error-handler/types'
import { translateCodeToMessage } from '@/error-handler/i18n'

export const extractGraphQLErrors = (graphQLErrors: Array<any>): Array<ApiError> => {
  const errors: Array<ApiError> = []

  if (graphQLErrors) {
    for (const elem of graphQLErrors) {
      errors.push({
        code: elem.code,
        messages: elem.code === VALIDATION_ERROR_CODE
          ? [translateCodeToMessage(elem.code, 'graphql.errorCodes')].concat(Object.values(elem.errors))
          : [translateCodeToMessage(elem.code, 'graphql.errorCodes')]
      })
    }
  }

  return errors
}
