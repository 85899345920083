export const loadPage = (component) => {
  return () => import(/* webpackChunkName: "page-[request]" */`@/pages/${component}.vue`)
}

export function pageFilterProps (route, initialFilter) {
  let filter = initialFilter

  if (route.query.filter) {
    if (typeof route.query.filter === 'object') {
      filter = { ...filter, ...route.query.filter }
    } else {
      route.query.filter.split('|').forEach(elem => {
        const [name, value] = elem.split(':')
        filter[name] = value
      })
    }
  }

  return {
    page: route.query.page ? Number.parseInt(route.query.page) : 1,
    filter: filter
  }
}
