export const HIDE_ADD_GENERIC = 'HIDE_ADD_GENERIC'

export const SET_MICROTREND = 'SET_MICROTREND'
export const RESET_MICROTREND = 'RESET_MICROTREND'

export const SHOW_ADD_TO_INNOVATIONFIELD = 'SHOW_ADD_TO_INNOVATIONFIELD'
export const SHOW_ADD_TO_WORKSPACE = 'SHOW_ADD_TO_WORKSPACE'

export const SHOW_CREATE_SEARCH_AGENT = 'SHOW_CREATE_SEARCH_AGENT'

export const SHOW_SNACKBAR = 'SHOW_SNACKBAR'
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR'

export const SHOW_TRENDRADAR_CREATE_RELATED = 'SHOW_TRENDRADAR_CREATE_RELATED'
export const SELECT_TRENDRADAR_CREATE_RELATED = 'SELECT_TRENDRADAR_CREATE_RELATED'

export const SHOW_PROPOSE_INNOVATION_PROJECT = 'SHOW_PROPOSE_INNOVATION_PROJECT'
export const HIDE_PROPOSE_INNOVATION_PROJECT = 'HIDE_PROPOSE_INNOVATION_PROJECT'

export const SET_MICROTRENDS_TO_PIN = 'SET_MICROTRENDS_TO_PIN'

export const SET_BACK_URL = 'SET_BACK_URL'

export const SET_VIEW_MODE = 'SET_VIEW_MODE'
export const SET_VIEW_CONFIG = 'SET_CONFIG'
export const SET_PAGINATION = 'SET_PAGINATION'
export const UPDATE_PAGINATION = 'UPDATE_PAGINATION'

export const SHOW_CREATE_FEEDBACK = 'SHOW_CREATE_FEEDBACK'
export const HIDE_CREATE_FEEDBACK = 'HIDE_CREATE_FEEDBACK'
export const SET_LAST_FEATURE_UPDATE = 'SET_LAST_FEATURE_UPDATE'
export const CLOSE_FEATURE_UPDATE_DIALOG = 'CLOSE_FEATURE_UPDATE_DIALOG'

export const SET_SHOW_UPDATE_DIALOG = 'SET_SHOW_UPDATE_DIALOG'
export const SET_WORKSPACE_DEPRECATED_DIALOG = 'SET_WORKSPACE_DEPRECATED_DIALOG'
