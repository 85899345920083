<template>
    <span
      :class="classes"
    >
        <slot />
    </span>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CounterBean extends Vue {
  @Prop({
    type: Boolean,
    default: () => false
  }) inverted

  get classes () {
    return `counter-bean ${this.inverted ? 'inverted' : ''}`
  }
}
</script>
