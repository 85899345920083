<template>
  <v-tooltip
    color="primary"
    z-index="500"
    top
  >
    <template v-slot:activator="{ on }">
      <div
        v-on="on"
        @click.stop
      >
        <v-menu
          offset-y
          left
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="primary"
              data-testid="share-button"
              title="Micro-Trend teilen"
              text
            >
              <v-icon
                small
              >
                mdi-share-variant
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-clipboard:copy="publicUrl"
              v-clipboard:success="onSuccess"
              v-clipboard:error="onError"
              class="v-list-item--link"
            >
              <v-list-item-title>
                {{ $t('components.microtrend.overlay.shareActionButton.copyToClipboard') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item :href="mailContent">
              <v-list-item-title>
                {{ $t('components.microtrend.overlay.shareActionButton.shareByMail') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <span>{{ $t('components.microtrend.overlay.shareActionButton.tooltip') }}</span>
  </v-tooltip>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { SHOW_SNACKBAR } from '@/store/gui/mutation-types'
import { namespace } from 'vuex-class'

const gui = namespace('gui')

@Component
export default class ShareActionButton extends Vue {
  @Prop({
    type: String,
    required: true
  }) publicUrl

  @gui.Mutation(SHOW_SNACKBAR) storeShowSnackbar

  get mailContent () {
    const subject = this.$t('components.microtrend.overlay.shareActionButton.subject')

    return `mailto:?subject=${subject}&body=${this.publicUrl}`
  }

  onError () {
    this.storeShowSnackbar({
      messages: [
        this.$t('components.microtrend.overlay.shareActionButton.copyError'),
        this.publicUrl
      ],
      mode: 'error'
    })
  }

  onSuccess () {
    this.storeShowSnackbar(this.$t('components.microtrend.overlay.shareActionButton.copySuccess'))
  }
}
</script>
